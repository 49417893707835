import { Controller } from "@hotwired/stimulus"
import {get, destroy} from "@rails/request.js"

export default class extends Controller {
    static values = { employeeId: Number, fileId: Number}
    static targets = ["container"];

    connect() {
        document.addEventListener("onModalBackdropClick", this.onClick.bind(this));
    }
    onClick(event) {
        event.preventDefault();
        let modal = document.querySelector('[data-components--element--modal-target="modalWithBackdrop"]')
        modal.classList.add('hidden')
        let uploadedFiles = modal.querySelector('#uploaded-files-form')
        uploadedFiles.innerHTML = ''
    }

    async editExpiryDate(event) {
        event.preventDefault()
        await get(`/${window.currentWorkspaceSlug}/employee_zone/employees/${this.employeeIdValue}/documents/${this.fileIdValue}/edit`,  {
                responseKind: "turbo-stream"
            }
        )
    }

    async deleteFile(event) {
        let isPersonal = event.currentTarget.closest('#employee-zone--employees--show--documents--index--updatable-content-wrapper').getAttribute('data-is-personal')
        event.preventDefault()
        await destroy(`/${window.currentWorkspaceSlug}/employee_zone/employees/${this.employeeIdValue}/documents/${this.fileIdValue}?is_personal=${isPersonal}`,  {
            responseKind: "turbo-stream"
        })
    }

    disconnect() {
        document.removeEventListener("onModalBackdropClick", this.onClick.bind(this));
    }
}