import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../../helpers/mustache_helper"
import { DateTime } from 'luxon'
import {destroy} from "@rails/request.js";
import Mark from "mark.js";

export default class extends Controller {
    static targets = [
        'resultsFound'
    ]

    static values = {
        streamChatChannelId: String,
        workspaceSlug: String
    }

    async connect() {
        this.setStreamChatClient()
        await this.setChannel()
        this.renderMessagesFound(0)
    }

    disconnect() {
        this.channelEventListener.unsubscribe()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async setChannel() {
        await this.chatIndexController.setCurrentChat(this.streamChatChannelIdValue);
        this.channel = this.chatIndexController.channels.find(channel => channel.id === this.streamChatChannelIdValue);
    }

    renderMessagesFound(messageCount) {
        this.resultsFoundTarget.innerHTML = ''
        this.resultsFoundTarget.prepend(
            MustacheHelper.toElement(
                'components--employee-zone--chat--channel-search--show--mustache-template--results-found',
                {
                    hasResults: messageCount > 0,
                    hasMultipleResults: messageCount > 1,
                    messageCount: messageCount
                }
            )
        )
    }

    async onSearchInput() {
        const searchTerm = this.element.querySelector('input').value.toLowerCase();
        let channelSearchListItemWrapper = document.getElementById('channel-search-channel-list');
        this.filteredChatMessages = await this.fetchSearchResults(searchTerm)

        channelSearchListItemWrapper.innerHTML = '';

        this.renderMessagesFound( this.filteredChatMessages.length)
        // Render the filtered list
        this.filteredChatMessages.forEach(message => {
            this.chatIndexController.displayChatMessage(channelSearchListItemWrapper, message);
        });

        // Apply highlighting to the search term
        this.mark = new Mark(channelSearchListItemWrapper);
        this.mark.mark(searchTerm, {
            className: 'bg-blue-light'
        });
    }

    async fetchSearchResults(searchTerm) {
        let filteredChatMessages = await this.chatIndexController.fetchMessages(searchTerm, this.channel.id);
        return filteredChatMessages.reverse()
    }

    async closedStyles() {
        await destroy(`/${this.workspaceSlugValue}/employee_zone/chat/channel_sidebars/1`)
    }

}
