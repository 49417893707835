import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
    static values = { scale: Number, max: Number }
    static targets = [ 'input', 'hiddenInput' ]

    connect() {
        this.inputTarget.value = this.hiddenInputTarget.value // This might cause problems with system tests
        this.maskOptions = {
            mask: Number,
            thousandsSeparator: "'",
            scale: this.scaleValue || 2,
            radix: ".",
            normalizeZeros: true, // Ensures leading zeros are handled correctly
            max: this.maxValue || Infinity,
        };

        this.mask = IMask(this.inputTarget, this.maskOptions);
    }

    onInput(event) {
        // Replace comma with a dot for decimal point
        let formattedValue = this.inputTarget.value.replace(',', '.');

        // Apply the formatted value back to the input field
        this.inputTarget.value = formattedValue;

        // Update hidden input value
        this.hiddenInputTarget.value = this.parseAndConvertNumber(this.inputTarget);
    }

    parseAndConvertNumber(element) {
        // Remove thousands separators (') and handle the decimal point
        let number = element.value.replace(/'/g, '');
        return !isNaN(Number(number)) ? Number(number) : 0;
    }

    disconnect() {
        if (this.mask) {
            this.mask.destroy()
        }
    }
}
