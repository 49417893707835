
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["primary", "secondary"]

  connect() {
    if (this.hasPrimaryTarget) {
      this.primaryTarget.classList.remove("hidden");
    }
    if (this.hasSecondaryTarget) {
      this.secondaryTarget.classList.add("hidden");
    }
  }

  toggle(event) {
    const element = event.currentTarget
    const hideElement = document.querySelector(element.dataset.hideTargetSelector)
    const showElement = document.querySelector(element.dataset.showTargetSelector)
    if (hideElement) {
      hideElement.classList.add("hidden")
    }
    if (showElement) {
      showElement.classList.remove("hidden")
    }

    if (this.hasPrimaryTarget) {
      this.primaryTarget.classList.toggle("hidden");
    }
    if (this.hasSecondaryTarget) {
      this.secondaryTarget.classList.toggle("hidden");
    }
  }
}
