import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../helpers/mustache_helper";
import { DateTime } from 'luxon'
import Mark from 'mark.js'
import { get } from "@rails/request.js";
export default class extends Controller {
    static targets = ["closeButton"]
    static values = { channelIdToOpen: String }

    async connect() {
        this.setStreamChatClient()
        await this.reloadChannelList()

        this.streamChatClient.on(
            'notification.added_to_channel',
            event => this.reloadChannelList()
        )


        this.groupChatButton = document.getElementById('group-chat-creation-btn')
        this.chatSortWrapper = document.getElementById('chat-sort-dropdown-wrapper')
        this.channelList = document.getElementById('channel-list')
        this.forwardedMessageId = null
        this.repliedMessages = []
        this.currentChannel = null

        if (this.channelIdToOpenValue !== null && this.channelIdToOpenValue !== undefined && this.channelIdToOpenValue !== '') {
            await this.openChannel()
        }
    }

    setStreamChatClient() {
        this.setStreamChatController()
        this.streamChatClient = this.streamChatController.streamChatClient
    }

    setStreamChatController() {
        const controllerName = 'components--employee-zone--layout-template--stream-chat'

        this.streamChatController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async reloadChannelList(sortNyName = false) {
        await this.fetchRightSidebarChannels(sortNyName);
        if ((this.streamChatController.deviceLoggedInValue && this.streamChatController.isConnectAppValue) || !this.streamChatController.isConnectAppValue) {
            this.renderChannelList(document.getElementsByClassName('channel-list')[0]);
        } else {
            const channel = this.channels[0]
            const otherMembers = this.otherMembers(channel)
            await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/channel_details?stream_chat_channel_id=${channel.id}&active_state=${this.activeState(otherMembers)}`, {
                responseKind: "turbo-stream"
            })
        }
    }

    expand() {
        if (this.isNativeValue === false) {
            this.groupChatButton.classList.add('hidden')
            this.chatSortWrapper.classList.add('hidden')
        }

        this.channelList.classList.add('hidden')
    }

    shrink(event) {
        if (!event.currentTarget.value) {
            this.closedStyles()
        }
    }

    closedStyles() {
        document.getElementById('search-channel-list').innerHTML = ''
        if (this.isNativeValue === false) {
            this.groupChatButton.classList.remove('hidden')
            this.chatSortWrapper.classList.remove('hidden')
        }

        this.channelList.classList.remove('hidden')
    }

    async fetchRightSidebarChannels(sortByName = false) {
        this.channelEventListeners?.forEach(listener => listener.unsubscribe());
        this.channelEventListeners = [];

        if (this.streamChatController.isConnectAppValue && !this.streamChatController.deviceLoggedInValue && this.streamChatController.departmentChannelIdValue !== null) {
            this.channels = [await this.streamChatController.fetchChannelById(`department_${this.streamChatController.departmentChannelIdValue}`)]
        } else {
            this.channels = await this.streamChatController.fetchChannels('', sortByName)
        }

        this.channelEventListeners = this.channels.map(channel => {
            return channel.on('message.new', event => {
                this.reloadChannelList();
            })
        })
        this.channelEventListeners.push(
            this.streamChatClient.on('user.presence.changed', event => {
                if (this.currentChannel == null) {
                    this.reloadChannelList();
                }
            })
        );
    }

    async fetchMessages(searchTerm = '', channelId = null) {
        if (!searchTerm) return [];

        let channelFilter = {};
        if (channelId) {
            // If channelId is provided, search within that specific channel
            channelFilter = { id: channelId };
        } else {
            // If no channelId is provided, search globally across channels where the user is a member
            channelFilter = { members: { $in: [this.streamChatUserIdValue] } };
        }

        const messageFilter = { text: { "$autocomplete": searchTerm } }

        const searchResults = await this.streamChatClient.search(
            channelFilter,                   // Search filter (either for a specific channel or globally)
            messageFilter,               // The search term to look for in the message content
            { limit: 100, offset: 0 }          // Adjust the limit as needed
        );

        // Extract and return messages from the search results
        const messages = searchResults.results.map(result => result.message);
        return messages;
    }

    async onSearchInput(event) {
        const searchTerm = event.currentTarget.querySelector('input').value.toLowerCase();
        let searchListItemWrapper = document.getElementById('search-channel-list');

        this.filteredChannels = await this.streamChatController.fetchChannels(searchTerm);

        searchListItemWrapper.innerHTML = '';

        // Render the filtered list
        this.filteredChannels.forEach(channel => {
            const otherMembers = this.otherMembers(channel);
            this.displayChats(searchListItemWrapper, otherMembers, channel, '');
        });

        // Apply highlighting to the search term
        this.mark = new Mark(searchListItemWrapper);
        this.mark.mark(searchTerm, {
            className: 'bg-blue-light'
        });
    }

    async setCurrentChat(selectedChannelId) {
        // Fetch the selected channel
        const newChannel = await this.streamChatController.fetchChannelById(selectedChannelId)

        const messageResponse = await newChannel.query({
            messages: { limit: 1 } // Get at least one message
        });

        // Check if the channel is already in the list
        const existingChannelIndex = this.channels.findIndex(channel => channel.id === newChannel.id);

        if ((existingChannelIndex === -1 || messageResponse.messages.length === 0) && !this.channels.includes(newChannel)) {
            // If not found and list is full, remove the least recent channel
            if (this.channels.length >= 30) {
                this.channels.pop(); // Remove the last channel (least recent)
            }

            // Add the new channel to the top of the list
            this.channels.unshift(newChannel);
        }
        this.renderChannelList(document.getElementsByClassName('channel-list')[0]);
    }

    getForwardMessageController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index--forward-message'

        this.forwardMessageController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    renderChannelList(element) {
        //on refresh check if there is an redirect message
        this.getForwardMessageController()
        if (this.forwardMessageController) {
            this.forwardMessageController.forwardMessage()
        }
        const channelListHtml = element
        const selectedChat = channelListHtml.getElementsByClassName('selected-chat')
        let selectedChatId = ''
        if (selectedChat.length > 0) {
            selectedChatId = selectedChat[0].id
        }
        channelListHtml.innerHTML = ''

        this.channels.map((channel) => {
            const otherMembers = this.otherMembers(channel)
            this.displayChats(channelListHtml, otherMembers, channel, selectedChatId)
        })
    }

    displayChats(element, otherMembers, channel, selectedChatId) {
        const messages = channel.state.messageSets[0].messages
        const mostRecentMessage = messages[messages.length - 1]

        let mostRecentMessageSentAt;

        if (!!mostRecentMessage) {
            const mostRecentMessageIsToday = this.messageSentAt(mostRecentMessage).hasSame(DateTime.now(), 'day')
            if (mostRecentMessageIsToday) {
                mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toLocaleString(DateTime.TIME_24_SIMPLE)
            } else {
                mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toFormat('dd.LL.y')
            }
        } else {
            mostRecentMessageSentAt = ''
        }

        const profilePicturePath = this.getProfilePicturePath(channel, otherMembers)
        const unreadMessageCount = channel.state.unreadCount

        const title = this.getTitle(channel, otherMembers)
        const initials = this.generateInitials(title)

        element.appendChild(
            MustacheHelper.toElement(
                'components--employee-zone--chat--index--mustache-template--chat-list-item',
                {
                    initials: initials,
                    title: title,
                    firstName: this.mapMemberProperty(otherMembers, 'firstname'),
                    lastName: this.mapMemberProperty(otherMembers, 'lastname'),
                    profilePicturePath: profilePicturePath,
                    hasProfilePicture: profilePicturePath !== '',
                    taskCount: this.taskCount(otherMembers),
                    function: this.mapMemberProperty(otherMembers, 'function'),
                    singleTask: this.taskCount(otherMembers) === 1,
                    functionColor: this.mapMemberProperty(otherMembers, 'functionColor'),
                    channelId: channel.id,
                    mostRecentMessage: mostRecentMessage?.text,
                    mostRecentMessageSentAt: mostRecentMessageSentAt,
                    isActiveText: this.getLastSeen(otherMembers),
                    isActive: this.checkIfOnline(otherMembers),
                    isGroupChat: channel.data.channel_type === 'group',
                    activeState: this.activeState(otherMembers),
                    isSelectedChat: channel.id === selectedChatId,
                    hasUnreadMessages: unreadMessageCount !== 0,
                    unreadMessagesCount: unreadMessageCount
                }
            )
        )
    }

    displayChatMessage(element, message) {
        const member = message.user
        const profilePicturePath = member.profilePicturePath

        const messageDate = new Date(message.created_at);

        const optionsMonth = { month: 'short' }; // Get the short month name
        const optionsDay = { day: '2-digit' }; // Get the day as two digits

        const month = messageDate.toLocaleDateString('de-CH', optionsMonth);
        const day = messageDate.toLocaleDateString('de-CH', optionsDay);

        const formattedDate = `${month} ${day}`; // "Sep 04"
        element.appendChild(
            MustacheHelper.toElement(
                'components--employee-zone--chat--channel-search--show--mustache-template--message-card',
                {
                    initials: this.generateInitials(member.name),
                    fullName: member.name,
                    messageDate: formattedDate,
                    message: message.html,
                    profilePicturePath: profilePicturePath,
                    hasProfilePicture: profilePicturePath !== '' && profilePicturePath !== '#',
                    messageId: message.id
                }
            )
        )
    }

    otherMembers(channel) {
        return (({ [this.streamChatController.streamChatUserIdValue]: _, ...rest }) => rest)(channel.state.members);
    }

    mapMemberProperty(otherMembers, property) {
        return this.streamChatController.mapMemberProperty(otherMembers, property)
    }

    taskCount(otherMembers) {
        if (otherMembers === undefined) {
            return ''
        }
        return Object.values(otherMembers).map(member => member.user.taskCount).reduce((sum, count) => sum + count, 0);
    }


    checkIfOnline(otherMembers) {
        if (!otherMembers) return '';

        // Reuse mapMemberProperty to check if users are online
        return Object.values(otherMembers)
            .some(member => member.user.online);
    }

    getLastSeen(otherMembers) {
        if (!otherMembers) return '';

        return this.mapMemberProperty(otherMembers, 'last_active')
            .split(', ')
            .map(lastActive => {
                // Convert valid dates and skip invalid ones
                if (lastActive) {
                    const date = new Date(lastActive);
                    if (!isNaN(date)) {
                        return date.toLocaleString(); // Format the date
                    }
                }
                return ''; // Return empty string if lastActive is invalid or empty
            })
            .filter(Boolean) // Remove empty strings
            .join(', ');
    }

    activeState(otherMembers) {
        if (this.checkIfOnline(otherMembers) === true) {
            return 'online';
        } else {
            if (this.getLastSeen(otherMembers) === '') {
                return 'invisible'
            } else {
                return 'offline'
            }
        }
    }

    getTitle(channel, otherMembers) {
        return this.streamChatController.getTitle(channel, otherMembers)
    }

    getProfilePicturePath(channel, otherMembers) {
        return this.streamChatController.getProfilePicturePath(channel, otherMembers)
    }

    messageSentAt(message) {
        let sentAt;

        switch (typeof message.created_at) {
            case 'object':
                sentAt = message.created_at.toISOString()
                break
            case 'string':
                sentAt = message.created_at
                break
        }

        return DateTime.fromISO(sentAt)
    }

    generateInitials(title) {
        // Split by commas for multiple names
        const names = title.split(',').map(name => name.trim());
        let firstTwoWords;

        if (names.length >= 2) {
            firstTwoWords = [names[0].split(' ')[0], names[1].split(' ')[0]]
        } else {
            firstTwoWords = names.flatMap(name => {
                const matches = name.match(/[A-Z][a-z]*|[a-z]+/g); // Match words or camel case parts
                return matches ? matches.slice(0, 2) : [name]; // Return matches or the original name
            });
        }

        // Extract initials
        let initials;
        if (firstTwoWords.length === 2) {
            initials = firstTwoWords
                .map(word => word[0]?.toUpperCase()) // Take the first letter and capitalize it
                .join(''); // Combine into initials
        } else {
            initials = [firstTwoWords[0].charAt(0).toUpperCase(), firstTwoWords[0].charAt(1).toUpperCase()].join('')
        }

        return initials;
    }

    async getMessageById(messageId) {
        return await this.streamChatClient.getMessage(messageId)
    }

    removeMessageAssociations(messageId) {
        // Remove from repliedMessages
        this.repliedMessages = this.repliedMessages.filter(
            (msg) => msg.messageId !== messageId
        );

        // Check and clear forwardedMessageId
        if (this.forwardedMessageId === messageId) {
            this.forwardedMessageId = null;
        }
    }

    async openChannel() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/channel_details?stream_chat_channel_id=${this.channelIdToOpenValue}`)
    }

}
