import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";
export default class extends Controller {
   static targets = ['notification']

   connect() {
      setTimeout(() => {
         this.close()
      }, 5000);
   }

   async openChat(event) {
      const channelId = event.currentTarget.getAttribute('data-channel-to-open-value')
      if (document.getElementById('channel-list-wrapper')) {
         await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/channel_details?stream_chat_channel_id=${channelId}`)
      } else {
         window.location.href = (`/${window.currentWorkspaceSlug}/employee_zone/chat?channel_id=${channelId}`)
      }
   }

   close() {
      this.notificationTarget.classList.add("transition-opacity", "duration-500", "opacity-0");

      // Wait for the fade-out transition to complete before removing the element
      setTimeout(() => {
         this.notificationTarget.parentElement.remove()
      }, 500); // 500ms matches the duration of the fade-out
   }
}
