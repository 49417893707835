import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["channelSearch"]
    async connect() {
        await this.setGlobalModalStateManager()
        this.globalModalStateManager.setZIndex(this.channelSearchTarget)
    }
    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }
}