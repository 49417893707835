import { Controller } from "@hotwired/stimulus"
import {get, post} from "@rails/request.js";

export default class extends Controller {
    static values = { messageId: String }

    connect() {
       this.setChatIndexController()
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async removeHeader() {
        await this.channelDetailsController.removeHeader(this.messageIdValue)
    }
}