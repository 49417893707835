import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["hide"];

    connect() {
        const divTarget = this.hideTarget;
        const nativeCheck = this.hideTarget.querySelector('div.nativecheck');
        if (nativeCheck.dataset.native) {
            this.resizeObserver = new ResizeObserver(() => {
                this.checkTargetSize(divTarget);
            });
            this.resizeObserver.observe(divTarget);
        }
    }

    checkTargetSize(target) {
        const targetWidth = target.getBoundingClientRect().width;
        const windowWidht = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (targetWidth < (windowWidht / 4) && (windowHeight > windowWidht)) {
            target.style.visibility = "hidden";
        } else {
            target.style.visibility = "visible";
        }
    }

    disconnect() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }
}
