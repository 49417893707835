import {Controller} from "@hotwired/stimulus"
import {enter, leave} from "el-transition";
import {DateTime} from "luxon";
import {get} from "@rails/request.js";

export default class extends Controller {
    static targets = ["activeStartTimeTimestamp", "timerOutputDiv", "workingTime", "breakTime"]
    static values = { workingTime: Number, breakTime: Number, isBreak: Boolean}

    connect() {
        this.checkAndScheduleRequest();
        this.updateTimePassed();
        this.totalInterval = setInterval(() => this.updateTotalTimePassed(), 1000);
        this.updateWorkingTime();
        this.updateBreakTime();
        if (this.isBreakValue) {
            const delay = (60 - (Number(this.breakTimeValue) + Number(this.updateTimePassed(0))) % 60) * 1000

            setTimeout(() => {
                // After the delay, start updating every minute
                this.updateBreakTime();
                this.breakInterval = setInterval(() => this.updateBreakTime(), 60000);
            }, delay);
        } else {
            // Calculate the delay until the next minute for working time
            let delay = (60 - (this.workingTimeValue % 60)) * 1000;
            if (delay >= 1000) {
                delay -= 1000
            }

            setTimeout(() => {
                // After the delay, start updating every minute
                this.updateWorkingTime();
                this.workInterval = setInterval(() => this.updateWorkingTime(), 60000);
            }, delay);
        }

    }

    checkAndScheduleRequest() {
        const activeStartDate = new Date(this.activeStartTimeTimestampTarget.value * 1000);
        const now = new Date();

        if (activeStartDate > now) {
            // Calculate the delay in milliseconds
            let delay = activeStartDate - now;
            delay += 2000; // 2 seconds later

            setTimeout(() => {
                this.visitTimeTracker();
            }, delay);
        }
    }

    async visitTimeTracker() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/time_tracker_mobile`);
    }

    updateTotalTimePassed() {
        const result = this.updateTimePassed(0);
        if (this.hasTimerOutputDivTarget) {
            const { hours, minutes, remainingSeconds} = this.convertSecondsToTime(result)
            this.timerOutputDivTarget.querySelector("span").innerHTML = `${hours}:${minutes}:${remainingSeconds}`;
        }
    }

    updateWorkingTime() {
        let result;

        if (this.isBreakValue) {
            const currentTime = this.updateTimePassed(0)
            const totalSeconds = Number(this.workingTimeValue) - Number(currentTime)
            const {hours, minutes} = this.convertSecondsToTime(totalSeconds)
            result = { hours: hours , minutes: minutes };
        } else {
            const { hours, minutes } = this.convertSecondsToTime(this.updateTimePassed(this.breakTimeValue))
            result = { hours: hours, minutes: minutes};
        }

        // Update the output target
        this.workingTimeTarget.querySelector("span").innerHTML = `${result.hours}h ${result.minutes}min`;
    }

    updateBreakTime() {
        let result;

        if (this.isBreakValue) {
            const { hours, minutes } = this.convertSecondsToTime(this.updateTimePassed(this.breakTimeValue))
            result = { hours: hours, minutes: minutes};
        } else {
            const { hours, minutes } = this.convertSecondsToTime(this.breakTimeValue);
            result = { hours, minutes };
        }

        // Update the output target
        this.breakTimeTarget.querySelector("span").innerHTML = `${result.hours}h ${result.minutes}min`;
    }

    convertSecondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        // Format with leading zeros if necessary
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return { hours: formattedHours, minutes: formattedMinutes, remainingSeconds: formattedSeconds };
    }

    updateTimePassed(seconds) {
        let startTime;
        if (this.isBreakValue) {
            startTime = new Date(this.activeStartTimeTimestampTarget.value * 1000 - seconds * 1000); // Convert seconds to milliseconds
        } else {
            startTime = new Date(this.activeStartTimeTimestampTarget.value * 1000 + seconds * 1000); // Convert seconds to milliseconds
        }

        const now = new Date();
        const diffMs = now - startTime;
        const diffSeconds = String(Math.floor(diffMs / 1000)).padStart(2, '0');

        return diffSeconds;
    }

    disconnect() {
        clearInterval(this.totalInterval);
        clearInterval(this.workInterval);
        clearInterval(this.breakInterval);
    }
}
