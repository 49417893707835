import { Controller } from "@hotwired/stimulus"
import IMask from "imask";

export default class extends Controller {
    static targets = ["inputs", "hiddenInput"]
    connect() {

        const maskOptions = {
            mask: '0',
            blocks: {
                '0': {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 9,
                },
            },
        };

        for (let i = 0; i < this.inputsTarget.children.length; i++) {
            IMask(this.inputsTarget.children[i], maskOptions);
            this.inputsTarget.children[i].addEventListener("input", (event) => this.handleInput(event));
            this.inputsTarget.children[i].addEventListener("keydown", (event) => this.handleArrowKeys(event));

        }

        this.inputs = Array.from(this.inputsTarget.children);
    }


    handleInput(event) {
        const value = event.target.value;
        this.updateHiddenInput();
        if (value.length === 1) {
            const currentIdentifier = Number(event.currentTarget.getAttribute('data_input'))
            if (currentIdentifier < 6) {
                this.inputsTarget.children[currentIdentifier].focus();
            }
        }

        if (this.inputs.every(input => input.value !== "")) {
            this.submitForm(); // Submit the form if all six fields are filled
        }
    }

    handleArrowKeys(event) {
        const cursorPosition = event.target.selectionStart;
        const valueLength = event.target.value.length;

        if ((event.key === "ArrowLeft" && cursorPosition === 0) || (event.key === "ArrowRight" && cursorPosition === valueLength)) {
            event.preventDefault();
            const currentIdentifier = Number(event.currentTarget.getAttribute('data_input'))
            if (event.key === "ArrowLeft") {
                if (currentIdentifier > 1) {
                    this.inputsTarget.children[currentIdentifier - 2].focus();
                }
            } else {
                if (currentIdentifier < 6) {
                    this.inputsTarget.children[currentIdentifier].focus();
                }
            }
        }
    }


    onClick(event) {
        const digit = event.currentTarget.innerText.trim(); // Get the digit from the button text

        // Find the first empty input field
        let input = this.inputs.find(input => input.value === "");
        if (!input) return; // If no empty field, exit

        // Set the input's value
        input.value = digit;
        this.updateHiddenInput();
        if (this.inputs.every(input => input.value !== "")) {
            this.submitForm(); // Submit the form if all six fields are filled
        }
    }

    onDelete() {
        // Find the last filled input field
        const filledInputs = this.inputs.filter(input => input.value !== "");
        if (filledInputs.length === 0) return; // If no filled inputs, exit

        // Clear the last filled input
        const lastFilledInput = filledInputs[filledInputs.length - 1];
        lastFilledInput.value = "";
        this.updateHiddenInput();
    }

    updateHiddenInput() {
        // Combine all input values and set it to the hidden input
        const fullCode = this.inputs.map(input => input.value).join("");
        this.hiddenInputTarget.value = fullCode;
    }

    submitForm() {
        this.element.querySelector("form").requestSubmit(); // Submit the form
    }

}
