import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'
import luxonPlugin from '@fullcalendar/luxon3'
import { DateTime } from 'luxon'
import camelcaseKeys from 'camelcase-keys'
import { get } from '@rails/request.js'
import MustacheHelper from "../../../../helpers/mustache_helper"

export default class extends Controller {
    static targets = ['calendarWrapper']
    static values = {
        departmentIds: Array,
        locationIds: Array,
        simplifiedUi: Boolean
    }

    connect() {
        this.loadAllData().then(() => {
            this.setupDatepicker();
            this.setupCalendar();
            this.setupLocationFilter();
            this.setupDepartmentFilter();
            this.setupTodayButton();
            this.setupCalendarFormatter();
            this.setupMyCalendarButton();
            this.filterIfSimplifiedUi();
            this.calendar.render();
        })
    }

    loadAllData = async () => {
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries.json`, 0);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/shifts.json`, 1);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/absences.json`, 2);
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/schedules.json`, 3);
    }

    loadCalendarEntries = async () => {
        await this.loadingQuery(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries.json`, 0);
    }

    loadingQuery = async (url, type) => {
        let fetchResponse = await get(url);
        if (fetchResponse.ok) {
            const data = await fetchResponse.response.json();
            if (type == 0) this.calendarEntriesResponce = camelcaseKeys(data, { deep: true });
            if (type == 1) this.shiftResponce = camelcaseKeys(data, { deep: true });
            if (type == 2) this.absenceResponce = camelcaseKeys(data, { deep: true });
            if (type == 3) this.scheduleResponce = camelcaseKeys(data, { deep: true });
        }
    }

    eventReloaderTargetConnected(_eventReloader) {
        this.reloadCalendarEntrys()
    }

    setupCalendar = () => {
        this.calendar = new Calendar(this.calendarWrapperTarget, {
            ...this.calendarBaseOptions(),
            ...this.calendarEventOptions(),
            ...this.calendarHeaderOptions(),
        })
    }

    setupDatepicker = () => {
        const datepickerWrapper = document.getElementById('calendar-datepicker-wrapper')
        const datepickerInput = datepickerWrapper.querySelector('input')
        this.setDatePickerCustomLabel(DateTime.fromFormat(datepickerInput.value, 'dd.MM.yyyy'))
        datepickerInput.addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')
            this.setDate(date)
        })
    }


    setupTodayButton = () => {
        const button = document.getElementById('today-button');
        button.addEventListener('click', (event) => {
            this.setDate(DateTime.now())
        })
    }

    setupMyCalendarButton = () => {
        const button = document.getElementById('my-calendar-button');
        this.currentEmployee = button.dataset.employeeId;
        this.isNative = button.dataset.native
        if (this.isNative) {
            this.filterByUser = 1
            this.publishedOnly = true;
            this.filterData(false);
        }
        else {
            const toggleInput = button.querySelector('input[name="toggle-active"]');
            this.filterByUser = toggleInput.value;
            this.moreInfo = true
            button.addEventListener('click', (event) => {
                this.filterByUser = toggleInput.value;
                this.filterData(false);
            })
        }
    }

    setupLocationFilter = () => {
        this.findDropDownElements('calendar--location-search--wrapper', 0)
    }

    setupDepartmentFilter = () => {
        this.findDropDownElements('calendar--department-search--wrapper', 1)
    }

    findDropDownElements = (id, isLocation) => {
        const dropDownWrapper = document.getElementById(id)
        const allInputs = dropDownWrapper.querySelectorAll('input');
        allInputs.forEach(input => {
            const listItem = input.parentNode.parentNode;
            this.setupEvent(input, input, 'change', isLocation)
            this.setupEvent(listItem, input, 'click', isLocation)
        })
    }

    setupEvent = (target, input, eventType, isLocation) => {
        target.addEventListener(eventType, (event) => {
            if (isLocation == 0) {
                this.locationIdsValue = this.updateList(this.locationIdsValue, input)
                this.filterData(false);
            }
            else if (isLocation == 1) {
                this.departmentIdsValue = this.updateList(this.departmentIdsValue, input)
                this.filterData(false);
            }
        })
    }

    setupCalendarFormatter = () => {
        const target = document.getElementById('calendar--format--wrapper');
        const webInputs = target.querySelectorAll('input:not(.native-input)')
        const nativeInputs = target.querySelectorAll('input.native-input')
        const dayviewDatepicker = document.getElementById('calendar-dayview-datepicker-wrapper');

        this.monthHeader = false;
        this.currentView = 7
        this.currentDate = DateTime.now()

        const nativeViewOptions = {
            1: { view: 'timeGridDay', startDate: this.currentDate.toISODate(), days: 1, moreInfo: true },
            2: { view: 'listWeek', startDate: this.currentDate.toISODate(), days: 1, moreInfo: true },
            3: { view: 'dayGridMonth', startDate: this.currentDate.startOf('month').toISODate(), days: 1, showHeader: true },
            7: { view: 'timeGridWeek', startDate: this.currentDate.startOf('week').toISODate(), days: 7 }
        }

        const webViewOptions = {
            1: { isDayView: true, jumpDate: this.currentDate.toISODate(), view: 1, moreInfo: true },
            7: { isDayView: false, jumpDate: this.currentDate.startOf('week').toISODate(), view: 7, moreInfo: true }
        }

        nativeInputs.forEach(input => {
            const listItem = input.parentNode;
            listItem.addEventListener('click', (event) => {
                const option = nativeViewOptions[parseInt(input.value)];
                if (option) {
                    this.nativeViewEdit(
                        dayviewDatepicker,
                        option.showHeader || false,
                        option.moreInfo || false,
                        option.view,
                        option.startDate,
                        option.days
                    );
                }
            })
        })

        webInputs.forEach(input => {
            const listItem = input.parentNode.parentNode;
            listItem.addEventListener('click', (event) => {
                const option = webViewOptions[parseInt(input.value)];
                if (option) {
                    this.webViewEdit(
                        dayviewDatepicker,
                        option.isDayView,
                        option.jumpDate,
                        option.view,
                        option.moreInfo
                    );
                }
            })
        })

        document.getElementById('calendar-dayview-datepicker-input').addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')

            if (date !== this.currentDate) {
                this.setDate(date)
            }
        })
    }

    nativeViewEdit(dayviewDatepicker, isMonthView, moreInfo, viewType, jumpDate, view) {
        if (!dayviewDatepicker.classList.contains('hidden')) {
            dayviewDatepicker.classList.add('hidden')
        }
        this.monthHeader = isMonthView;
        this.moreInfo = moreInfo;
        this.calendar.changeView(viewType, jumpDate)
        this.calendar.setOption('slotEventOverlap', !isMonthView)
        this.currentView = view
        this.datepickerView = viewType
        if (isMonthView) {
            this.calendar.setOption('eventDisplay', 'block');
            this.monthViewEntryValidation()
        } else {
            this.calendar.setOption('eventDisplay', 'auto');
        }
        const setDate = DateTime.fromISO(jumpDate);
        this.setDate(setDate)
        this.calendar.render();
    }

    webViewEdit(dayviewDatepicker, isDayView, jumpDate, view, moreInfo) {
        isDayView ? dayviewDatepicker.classList.remove('hidden') :
            dayviewDatepicker.classList.add('hidden')
        this.calendar.setOption('duration', { days: view })
        this.calendar.gotoDate(jumpDate)
        this.currentView = view
        this.datepickerView = view
        this.moreInfo = moreInfo
        const setDate = DateTime.fromISO(jumpDate);
        this.setDate(setDate)
        this.calendar.render();
    }

    setDate = (date) => {
        this.currentDate = date

        document.getElementById('calendar-dayview-datepicker-input').value = date.toFormat('dd.MM.yyyy')
        this.setDatePickerCustomLabel(date)

        const datepickerController = this.application.getControllerForElementAndIdentifier(
            document.getElementById('calendar-dayview-datepicker-controller'),
            'components--form--datepicker'
        )
        datepickerController.setDate(date.toFormat('yyyy-MM-dd'))

        switch (this.currentView) {
            case 1:
                this.calendar.gotoDate(date.toISODate())
                break;
            case 7:
                this.calendar.gotoDate(date.startOf('week').toISODate())
                break;
        }
        this.monthViewEntryValidation();
    }

    setDatePickerCustomLabel(date) {
        document.getElementById('calendar-datepicker-wrapper').querySelector('input').value = this.changeDatepickerLabelView(date)
    }

    changeDatepickerLabelView = (date) => {
        const currentView = this.datepickerView;
        let formattedLabel = '';
        if (currentView === 'dayGridMonth') {
            // Format: December 2023
            formattedLabel = date.toFormat('MMMM yyyy');
        } else if ((currentView === 'timeGridDay') || (currentView === 1)) {
            // Format: Monday 13th
            const daySuffix = this.getDaySuffix(date.day);
            formattedLabel = `${date.toFormat('cccc')} ${date.day}${daySuffix}`;
        } else {
            // Format: KW42
            const weekNumber = date.toFormat('W');
            formattedLabel = `KW${weekNumber}`;
        }
        return formattedLabel;
    }

    getDaySuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    updateList = (list, input) => {
        return input.checked ? [...list, input.value] : list.filter(id => id != input.value);
    };

    calendarBaseOptions = () => ({
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        plugins: [interactionPlugin, luxonPlugin, timeGridPlugin, rrulePlugin, listPlugin, dayGridPlugin],

        initialView: 'timeGrid',
        initialDate: DateTime.now().startOf('week').toISODate(),
        duration: { days: 7 },
        slotDuration: { hours: 1 },
        firstDay: 1, // Start the week on monday

        headerToolbar: false,

        contentHeight: 'auto',

        // TODO handle scrolling better
        stickyHeaderDates: true,
    })

    calendarEventOptions = () => ({
        eventSources: [
            this.absenceResponce,
            this.calendarEntriesResponce,
            this.shiftResponce,
            this.scheduleResponce
        ],
        eventDataTransform: function (eventData) {
            return camelcaseKeys(eventData, { deep: true })
        },

        eventClassNames: 'bg-transparent border-0',

        eventContent: (eventArgument) => {
            const templateValues = eventArgument.event.extendedProps?.templateValues

            return this.sortData(templateValues)
        },

        eventClick: async function (argument) {
            const entryType = argument.event._def.extendedProps.templateValues.entryType
            const date = DateTime.fromJSDate(argument.event.start)
            if (['meeting', 'event', 'internal_event', 'task'].includes(entryType)) {
                const calendarEntryId = argument.event.id.replace(/\D/g, '')
                await get(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries/${calendarEntryId}?entry_type=${entryType}&date=${date}`, { responseKind: "turbo-stream" })
            }
        }
    })

    calendarHeaderOptions = () => ({
        dayHeaderContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--day-header'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const currentDateTime = DateTime.fromJSDate(new Date())
            const placeholders = {
                isMonthHeader: this.monthHeader,
                dayOfMonth: selectedDateTime.toFormat('dd'),
                dayOfWeek: selectedDateTime.toFormat('ccc'),
                isCurrentDay: selectedDateTime.hasSame(currentDateTime, 'day')
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        slotLabelContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--slot-label'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const placeholders = {
                // TODO: I did a quick fix to not show the hour when it's 00:00
                // It's tricky to display it above the other content (z-index seems not to work)
                time: selectedDateTime.hour !== 0 ? selectedDateTime.toFormat('HH:mm') : '',
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        allDayContent: false
    })

    shiftElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--shift'

        const shiftStartTime = this.formatTime(templateValues.shiftStart)
        const shiftEndTime = this.formatTime(templateValues.shiftEnd)
        templateValues.shiftRange = `${shiftStartTime} - ${shiftEndTime}`
        templateValues.isMonthView = this.monthHeader
        templateValues.durationHours = (templateValues.durationSeconds / 3600).toFixed(2)
        templateValues.moreInfo = this.moreInfo

        templateValues.breakRanges = templateValues.shiftBreaks.map((shiftBreak, index, shiftBreaks) => {
            const breakStartTime = this.formatTime(shiftBreak.breakStart)
            const breakEndTime = this.formatTime(shiftBreak.breakEnd)
            let breakRange = `${breakStartTime} - ${breakEndTime}`
            if (index !== shiftBreaks.length - 1) {
                breakRange += ', '
            }
            return breakRange;
        })

        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'

        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColor
            },
            {
                target: '.published-background-color-target',
                style: 'backgroundColor',
                value: templateValues.published ? templateValues.publishedBackgroundColor : 'white'
            }
        ]

        return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
    }

    absenceElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--absence'
        templateValues.publishedBackgroundClass = templateValues.published ? 'bg-canvas-darker' : 'bg-white'
        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'
        templateValues.isMonthView = this.monthHeader
        const placeholders = { ...templateValues }
        const styles = []
        const absenceFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        const iconTemplateId = `components--employee-zone--calendar--index--absence-icon-${templateValues.iconName}`
        absenceFragment.querySelector('.icon-wrapper').innerHTML = document.getElementById(iconTemplateId).innerHTML
        return { domNodes: [absenceFragment] }
    }

    scheduleElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--schedule'
        const scheduleStartTime = this.formatTime(templateValues.startTime)
        const scheduleEndTime = this.formatTime(templateValues.endTime)
        templateValues.scheduleRange = `${scheduleStartTime} - ${scheduleEndTime}`
        templateValues.isMonthView = this.monthHeader
        templateValues.moreInfo = this.moreInfo
        const placeholders = { ...templateValues }
        const styles = []
        const scheduleFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [scheduleFragment] }
    }

    calendarEntryElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--calendar-entry'
        templateValues.isTask = templateValues.entryType === 'task'
        templateValues.acceptedBackgroundClass = (templateValues.participationState === 'accepted' || templateValues.isTask) ? 'background-color-target' : 'bg-white'
        templateValues.acceptanceStateClass = templateValues.participationState
        templateValues.textColorClass = ['bg-blue', 'bg-green'].includes(templateValues.acceptedBackgroundClass) ? 'text-canvas-white' : 'text-ink'
        templateValues.isMonthView = this.monthHeader
        templateValues.moreInfo = this.moreInfo
        templateValues.employees.forEach(employee => {
            const nameParts = employee.name.split(' ')
            employee.initials = nameParts.map(namePart => namePart[0]).join('')
        })
        const calendarEntryStartTime = this.formatTime(templateValues.startTime)
        const calendarEntryEndTime = this.formatTime(templateValues.endTime)
        templateValues.calendarEntryRange = `${calendarEntryStartTime} - ${calendarEntryEndTime}`
        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColorHex
            },
            {
                target: '.text-color-target',
                style: 'color',
                value: templateValues.textColorHex
            },
        ]

        const calendarEntryFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [calendarEntryFragment] }
    }

    trippleDotsElement = () => {
        const templateId = "components--employee-zone--calendar--index--more-entrys"
        const placeholders = {}
        const styles = []
        const calendarEntryFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [calendarEntryFragment] }
    }

    sortData(templateValues) {
        if (!templateValues) {
            return { domNodes: [] }
        } else if (templateValues.entryType === 'shift') {
            return this.shiftElement(templateValues);
        } else if (templateValues.entryType === 'absence') {
            return this.absenceElement(templateValues);
        } else if (['meeting', 'event', 'internal_event', 'task'].includes(templateValues.entryType)) {
            return this.calendarEntryElement(templateValues);
        } else if (templateValues.entryType === 'schedule') {
            return this.scheduleElement(templateValues)
        }
    }

    formatTime = (time) => (DateTime.fromObject({
        hour: time.hour,
        minute: time.minute
    }).toLocaleString(DateTime.TIME_24_SIMPLE))

    filterData(isFetch) {
        this.filterShifts();
        this.filterCalenderEntries(isFetch);
        this.filterAbsences();
        this.mergeAndCountEntrys();
        this.calendar.removeAllEventSources()
        this.calendar.addEventSource(this.combinedResponce)
        this.monthViewEntryValidation();
    }

    filterCalenderEntries(isFetch) {
        if (!this.allEntries || isFetch) {
            this.allEntries = this.calendarEntriesResponce
        }
        this.calendarEntriesResponce = this.allEntries
        if (this.departmentIdsValue.length > 0) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.departments.some(department => {
                    const departmentIdAsString = String(department.id);
                    return this.departmentIdsValue.includes(departmentIdAsString);
                });
            });
        }
        if (this.locationIdsValue.length > 0) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.locations.some(location => {
                    const locationIdAsString = String(location.id);
                    return this.locationIdsValue.includes(locationIdAsString);
                });
            });
        }
        if (this.filterByUser == 1) {
            this.calendarEntriesResponce = this.calendarEntriesResponce.filter(item => {
                return item.templateValues.allEmployees.some(employee => {
                    const employeeIdAsString = String(employee.id);
                    return this.currentEmployee == employeeIdAsString;
                });
            });
        }
    }

    filterShifts() {
        if (!this.allShifts) {
            this.allShifts = this.shiftResponce
        }

        this.shiftResponce = this.allShifts
        if (this.departmentIdsValue.length > 0) {
            this.shiftResponce = this.shiftResponce.filter(item => {
                const departmentIdAsString = String(item.templateValues.departmentId);
                return this.departmentIdsValue.includes(departmentIdAsString);
            });
        }
        if (this.locationIdsValue.length > 0) {
            this.shiftResponce = this.shiftResponce.filter(item => {
                const locationIdAsString = String(item.templateValues.locationId);
                return this.locationIdsValue.includes(locationIdAsString);
            });
        }
        if (this.filterByUser == 1) {
            if (this.publishedOnly) {
                this.shiftResponce = this.shiftResponce.filter(item => {
                    return item.templateValues.published;
                });
            }
            this.shiftResponce = this.shiftResponce.filter(item => {
                const employeeIdAsString = String(item.templateValues.employeeId);
                return this.currentEmployee == employeeIdAsString;
            });
        }
    }

    filterAbsences() {
        if (!this.allAbsences) {
            this.allAbsences = this.absenceResponce
        }
        this.absenceResponce = this.allAbsences
        if (this.filterByUser == 1) {
            if (this.publishedOnly) {
                this.absenceResponce = this.absenceResponce.filter(item => {
                    return item.templateValues.published;
                });
            }
            this.absenceResponce = this.absenceResponce.filter(item => {
                const employeeIdAsString = String(item.templateValues.employeeId);
                return this.currentEmployee == employeeIdAsString;
            });
        }
    }

    mergeAndCountEntrys() {
        this.combinedResponce = [
            ...this.shiftResponce,
            ...this.absenceResponce,
            ...this.calendarEntriesResponce,
            ...this.scheduleResponce
        ];
    }

    reloadCalendarEntrys = (event) => {
        this.loadCalendarEntries().then(() => {
            this.filterData(true);
            this.filterIfSimplifiedUi()
            this.monthViewEntryValidation()
        })
    }

    filterIfSimplifiedUi() {
        if (this.simplifiedUiValue) {
            const button = document.getElementById('my-calendar-button');
            this.currentEmployee = button.dataset.employeeId;
            this.filterByUser = 1;
            this.filterData(false);
        }
    }

    monthViewEntryValidation() {
        if (this.monthHeader) {
            const fields = document.querySelectorAll(".fc-daygrid-day-events");

            fields.forEach(field => {
                const events = field.querySelectorAll(".fc-daygrid-event-harness");
                this.monthViewEventDisplay(events)
                if (events.length > 4) {
                    events.forEach((event, index) => {
                        if (index > 2) {
                            event.classList.add("hidden");
                        }
                    });

                    if (!field.querySelector(".tripple-dots")) {
                        const { domNodes } = this.trippleDotsElement();

                        domNodes.forEach(node => {
                            node.classList.add("tripple-dots");
                            node.style.marginTop = "0px";
                            field.appendChild(node);
                        });
                    }
                }
            })
            this.calendar.render();
        }
    }

    monthViewEventDisplay(events) {
        events.forEach(e => {
            if (e.classList.contains("fc-daygrid-event-harness-abs")) {
                e.classList.remove("fc-daygrid-event-harness-abs")
            }
            e.removeAttribute("style");
            e.style.marginTop = "0px";
        })
    }
}
