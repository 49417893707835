import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["arrowIcon", "scope", "check"]

    connect() {
        this.sortDirection = "desc"
    }

    changeScope(event) {
        const newScope = event.target.parentNode.parentNode.querySelector('input[type="hidden"]')
        this.scopeTarget.value = newScope.value
        this.toggleCheckmark(event.target.parentNode.querySelector('.check-icon').parentNode)
    }

    toggleArrow(event) {
        this.arrowIconTargets.forEach(element => {
            element.classList.remove("hidden")
            const direction = element.querySelector('input[type="hidden"]')
            if (direction.value != this.sortDirection) {
                element.classList.add("hidden")
            }
        });

        this.sortDirection = this.sortDirection == "desc" ? "asc" : "desc"
    }

    toggleCheckmark(element) {
        this.checkTargets.forEach(target => {
            if (!target.classList.contains("hidden")) {
                target.classList.add("hidden")
            }
        })
        element.classList.remove("hidden")
    }
}
