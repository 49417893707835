import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
    static targets = ["signatureInput", "placeholder"];

    connect() {
        this.canvas = this.element.querySelector("#signature-pad");
        this.placeholderTarget.style.visibility = "visible";
        this.signaturePad = new SignaturePad(this.canvas, {
            minWidth: 0.5,
            maxWidth: 1.5
        });
        this.canvasResize()

        // Event listener to detect the beginning of drawing
        this.signaturePad.onBegin = this.hidePlaceholder.bind(this);
    }

    canvasResize() {
        this.signaturePad.set('minWidth', 1);
        this.signaturePad.set('canvasWidth', this.canvas.offsetWidth);
        this.signaturePad.set('canvasHeight', this.canvas.offsetHeight);
    }


    hidePlaceholder() {
        this.placeholderTarget.style.visibility = "hidden";
    }

    clear() {
        this.signaturePad.clear();
    }

    save() {
        if (this.signaturePad.isEmpty()) {
            alert("Please provide a signature first.");
            return;
        }

        const dataUrl = this.signaturePad.toDataURL(); // Base64 image
        this.signatureInputTarget.value = dataUrl
        this.element.querySelector('form').requestSubmit()
    }
}
