import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon3'
import { RRule } from 'rrule'
import { DateTime } from 'luxon'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import camelcaseKeys from 'camelcase-keys'
import decamelizeKeys from 'decamelize-keys'
import { get, post, patch } from '@rails/request.js'
import MustacheHelper from "../../../../helpers/mustache_helper"

export default class extends Controller {
    static values = {
        eventLabel: String,
        departmentIds: Array,
        locationIds: Array
    }
    static fullResponse;
    static targets = ['timelineWrapper', 'eventReloader', 'shiftAndAbsenceTemplateList']

    async connect() {
        await this.setZIndex()
        this.preloadData().then(() => {
            this.setupCalendar();
            this.setupDraggableItems();
            this.setupDatepicker();
            this.calendar.render();
            this.setupDepartmentFilter();
            this.setupLocationFilter();
        });
        this.startFilterAfterFirstLoad();
    }

    async setZIndex() {
        await this.setGlobalModalStateManager();
        this.globalModalStateManager.setZIndex(this.shiftAndAbsenceTemplateListTarget)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    preloadData = async () => {
        await this.loadScheduleData();
        await this.loadEventData();
    }

    loadScheduleData = async () => {
        const fetchResponse = await get(`/${window.currentWorkspaceSlug}/employee_zone/work_schedule/schedules.json`)
        if (fetchResponse.ok) {
            const data = await fetchResponse.response.json()
            this.scheduleData = camelcaseKeys(data, { deep: true })
        }
    }

    loadEventData = async () => {
        const fetchResponse = await get(`/${window.currentWorkspaceSlug}/employee_zone/work_schedule/events.json`)
        if (fetchResponse.ok) {
            const data = await fetchResponse.response.json()
            this.eventData = camelcaseKeys(data, { deep: true })
        }
    }

    headerReloaderTargetConnected(_eventReloader) {
        this.preloadData().then(() => {
            this.calendar.render()
        })
    }

    eventReloaderTargetConnected(_eventReloader) {
        this.reloadEvents()
    }

    setupCalendar = () => {
        this.calendar = new Calendar(this.timelineWrapperTarget, {
            ...this.calendarBaseOptions(),
            ...this.calendarResourceOptions(),
            ...this.calendarEventOptions(),
            ...this.calendarHeaderOptions(),
            ...this.calendarDragAndDropOptions(),
        })
    }

    setupDraggableItems = () => {
        this.draggableTemplates()
        this.draggableEntrys()
        this.dragAnimationEvent()
    }

    draggableTemplates = () => {
        new Draggable(document.getElementById('shift-and-absence-template-list'), {
            itemSelector: '.draggable-item',
        })
    }

    draggableEntrys = () => {
        new Draggable(document.getElementById('employee-zone--work-schedule--index--frame'), {
            itemSelector: '.draggable-item',
        })
    }

    dragAnimationEvent = () => {
        document.body.addEventListener("mousedown", (event) => {
            this.mouseDownEvent(event);
        });

        document.body.addEventListener('mouseup', (event) => {
            this.mouseUpEvent(event);
        });
    };

    mouseDownEvent = async (event) => {
        this.mouseDown = true;
        document.body.addEventListener('mousemove', this.searchForEventElement, { once: true });
    };

    mouseUpEvent = async (event) => {
        this.mouseDown = false;
        document.body.removeEventListener('mousemove', this.searchForEventElement, { once: true });
        this.removeClonedElement();
    };

    searchForEventElement = (event) => {
        if (!this.mouseDown) return;

        let draggingElement = document.querySelector('.fc-event-dragging');
        if (!draggingElement) {
            draggingElement = event.target.closest('.draggable-item');
        }

        if (draggingElement) {
            this.startDragging(event, draggingElement);
        } else {
            requestAnimationFrame(() => this.searchForEventElement(event));
        }
    };

    startDragging = (event, draggingElement) => {
        this.clonedElement = draggingElement.cloneNode(true);
        this.clonedElement.style.position = 'absolute';
        this.clonedElement.style.pointerEvents = 'none';
        this.clonedElement.style.display = "block";
        this.clonedElement.style.zIndex = '9999';
        this.clonedElement.classList.remove('fc-event-dragging', 'fc-dragging');
        const rect = draggingElement.getBoundingClientRect();
        this.clonedElement.style.width = `${rect.width}px`;
        this.clonedElement.style.height = `${rect.height}px`;

        document.body.appendChild(this.clonedElement);

        this.offsetX = rect.width / 2;
        this.offsetY = rect.height / 2;

        this.moveElementWithMouse(event);
        this.boundMoveElementWithMouse = this.moveElementWithMouse.bind(this);
        document.body.addEventListener('mousemove', this.boundMoveElementWithMouse);
    };

    moveElementWithMouse = (event) => {
        if (this.clonedElement) {
            this.clonedElement.style.left = `${event.pageX - this.offsetX}px`;
            this.clonedElement.style.top = `${event.pageY - this.offsetY}px`;
        }
    };

    removeClonedElement = () => {
        if (this.clonedElement) {
            this.clonedElement.remove();
            this.clonedElement = null;
            document.body.removeEventListener('mousemove', this.boundMoveElementWithMouse);
        }
    };


    setupDatepicker = () => {
        const datepickerWrapper = document.getElementById('work-schedule-datepicker-wrapper')
        const datepickerInput = datepickerWrapper.querySelector('input')
        datepickerInput.addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')
            this.calendar.gotoDate(date.toISODate())
            this.filterUser()
        })
    }

    setupLocationFilter = () => {
        this.findDropDownElements('work-schedule--location-search--wrapper', true)
    }

    setupDepartmentFilter = () => {
        this.findDropDownElements('work-schedule--department-search--wrapper', false)
    }

    findDropDownElements = (id, isLocation) => {
        const dropDownWrapper = document.getElementById(id)
        const allInputs = dropDownWrapper.querySelectorAll('input');
        allInputs.forEach(input => {
            const listItem = input.parentNode.parentNode;
            this.setupEvent(input, input, 'change', isLocation)
            this.setupEvent(listItem, input, 'click', isLocation)
        })
    }

    setupEvent = (target, input, eventType, isLocation) => {
        target.addEventListener(eventType, (event) => {
            if (isLocation) {
                this.locationIdsValue = this.updateList(this.locationIdsValue, input)
            }
            else {
                this.departmentIdsValue = this.updateList(this.departmentIdsValue, input)
            }
            this.filterUser();
        })
    }

    updateList = (list, input) => {
        return input.checked ? [...list, input.value] : list.filter(id => id != input.value);
    };

    calendarBaseOptions = () => ({
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        plugins: [interactionPlugin, luxonPlugin, resourceTimelinePlugin],

        initialView: 'resourceTimeline',
        initialDate: DateTime.now().startOf('week').toISODate(),
        duration: { days: 7 },
        slotDuration: { days: 1 },

        headerToolbar: false,

        contentHeight: 'auto',

        // TODO handle scrolling after the sticky footer has been created
        stickyHeaderDates: true,
    })

    calendarResourceOptions = () => ({
        resources: async (fetchInfo, successCallback, failureCallback) => {
            const fetchResponse = await get(`/${window.currentWorkspaceSlug}/employee_zone/work_schedule/contract_functions`)
            if (fetchResponse.ok) {
                const data = await fetchResponse.response.json()
                const camelCasedData = camelcaseKeys(data, { deep: true })

                this.fullResponse = camelCasedData;
                successCallback(camelCasedData)
            }
        },

        // I didn't find a way yet to make this dynamic
        resourceAreaWidth: '20rem',

        resourceLabelContent: (resourceArgument) => {

            const templateId = 'components--employee-zone--work-schedule--index--job-position'
            const templateValues = resourceArgument.resource.extendedProps.templateValues
            const nameParts = templateValues.employeeName.split(' ')
            templateValues.initials = nameParts.map(namePart => namePart[0]).join('')
            const placeholders = { ...templateValues }
            const styles = [
                {
                    target: '.job-position-headliner-badge',
                    style: 'backgroundColor',
                    value: templateValues.badgeBackgroundColor
                },
                {
                    target: '.job-position-headliner-badge',
                    style: 'color',
                    value: templateValues.badgeTextColor
                },
                {
                    target: '.initials-background',
                    style: 'backgroundColor',
                    value: templateValues.badgeTextColor
                }
            ]
            return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }

        },
    })

    calendarEventOptions = () => ({
        eventSources: [
            {
                id: 'shifts',
                url: `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/shifts.json`,
            }, {
                id: 'absences',
                url: `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/absences.json`,
            }
        ],
        eventDataTransform: function (eventData) {
            return camelcaseKeys(eventData, { deep: true })
        },

        eventClassNames: 'bg-transparent border-0',

        eventContent: (eventArgument) => {
            const templateValues = eventArgument.event.extendedProps?.templateValues
            if (!templateValues) {
                return { domNodes: [] }
            } else if (templateValues.entryType === 'shift') {
                templateValues.isPast = eventArgument.isPast
                return this.shiftElement(templateValues);
            } else if (templateValues.entryType === 'absence') {
                return this.absenceElement(templateValues);
            }
        }
    })

    calendarHeaderOptions = () => ({
        slotLabelContent: (arg) => {
            const templateId = 'components--employee-zone--work-schedule--index--day-header'
            const placeholders = {
                date: DateTime.fromJSDate(arg.date).toFormat('dd.MM.yy'),
                dayOfWeek: DateTime.fromJSDate(arg.date).toFormat('ccc'),
                events: this.filterEventsForDay(arg.date),
                schedules: this.getSchedulesForDay(arg.date)
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },

        resourceAreaHeaderContent: (arg) => {
            const templateId = 'components--employee-zone--work-schedule--index--total-hours'
            return { domNodes: [MustacheHelper.toElement(templateId)] }
        },
    })

    calendarDragAndDropOptions = () => ({
        droppable: true,
        drop: async (eventData) => {
            if (this.isProcessing) return;
            this.isProcessing = true;
            try {
                const templateData = camelcaseKeys(JSON.parse(eventData.draggedEl.dataset.calendarEntry), { deep: true })
                if (!(templateData.templateType === "hover_button")) {
                    if (templateData.shiftId || templateData.absenceId) { //Redragg Entry
                        let requestPath
                        if (templateData.templateType === "shift") {
                            requestPath = `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/shifts/${templateData.shiftId}.json`;
                        } else if (templateData.templateType === "absence") {
                            requestPath = `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/absences/${templateData.absenceId}.json`;
                        }
                        this.updateEntry(eventData, requestPath)
                    } else { //New Entry
                        const requestBody = {
                            date: eventData.dateStr,
                            contractFunctionId: eventData.resource.id,
                            templateId: templateData.templateId
                        }
                        const snakeCaseRequestBody = decamelizeKeys(requestBody, { deep: true })
                        await this.createEntry(snakeCaseRequestBody, templateData.templateType)
                    }
                } else {
                    this.duplicateEntryCol(eventData, templateData)
                }
            }
            finally {
                this.isProcessing = false;
            }
        },
    })

    shiftElement = (templateValues) => {
        const templateId = 'components--employee-zone--work-schedule--index--shift'

        const shiftStartTime = this.formatTime(templateValues.shiftStart)
        const shiftEndTime = this.formatTime(templateValues.shiftEnd)
        templateValues.shiftRange = `${shiftStartTime} - ${shiftEndTime}`
        templateValues.durationHours = (templateValues.durationSeconds / 3600).toFixed(2)
        templateValues.hasViolation = (templateValues.worktimeLimitViolation != null)


        const allTimeRanges = [];
        const allBreaks = [];
        let workTime = 0;
        templateValues.timeEntrys.forEach(entry => {
            const timeRange = `${this.grabTime(entry.trackedStartTime)} - ${this.grabTime(entry.trackedEndTime)}`;
            allTimeRanges.push(timeRange);
            workTime += (entry.worktimeMinutes)
            entry.breaks.forEach(breakPeriod => {
                const breakRange = `${this.formatTime(breakPeriod.startTime)} - ${this.formatTime(breakPeriod.endTime)}`;
                allBreaks.push(breakRange);
            });
        });

        templateValues.workedDurationHours = (workTime / 60).toFixed(2)
        templateValues.workedRanges = allTimeRanges
        templateValues.finalBreaks = allBreaks

        templateValues.breakRanges = templateValues.shiftBreaks.map((shiftBreak, index, shiftBreaks) => {
            const breakStartTime = this.formatTime(shiftBreak.breakStart)
            const breakEndTime = this.formatTime(shiftBreak.breakEnd)
            let breakRange = `${breakStartTime} - ${breakEndTime}`
            if (index !== shiftBreaks.length - 1) {
                breakRange += ', '
            }
            return breakRange;
        })

        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'

        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColor
            },
            {
                target: '.published-background-color-target',
                style: 'backgroundColor',
                value: templateValues.published ? templateValues.publishedBackgroundColor : 'white'
            }
        ]

        return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
    }

    absenceElement = (templateValues) => {
        const templateId = 'components--employee-zone--work-schedule--index--absence'
        templateValues.publishedBackgroundClass = templateValues.published ? 'bg-canvas-darker' : 'bg-white'
        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'
        const placeholders = { ...templateValues }
        const styles = []
        const absenceFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        const iconTemplateId = `components--employee-zone--work-schedule--index--absence-icon-${templateValues.iconName}`
        absenceFragment.querySelector('.icon-wrapper').innerHTML = document.getElementById(iconTemplateId).innerHTML
        return { domNodes: [absenceFragment] }
    }

    formatTime = (time) => (DateTime.fromObject({
        hour: time.hour,
        minute: time.minute
    }).toLocaleString(DateTime.TIME_24_SIMPLE))

    grabTime(dateString) {
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    getSchedulesForDay(date) {
        const dayOfWeek = DateTime.fromJSDate(date).weekday;
        const filteredSchedules = this.scheduleData.filter(schedule => {
            switch (dayOfWeek) {
                case 1: return schedule.onMonday === true;
                case 2: return schedule.onTuesday === true;
                case 3: return schedule.onWednesday === true;
                case 4: return schedule.onThursday === true;
                case 5: return schedule.onFriday === true;
                case 6: return schedule.onSaturday === true;
                case 7: return schedule.onSunday === true;
                default: return false;
            }
        });
        return filteredSchedules;
    }

    filterEventsForDay(date) {
        const formattedDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
        const filteredEvents = this.eventData.filter(event => {
            if (event.start == formattedDate) {
                return true
            }

            if (event.rrule) {
                const rule = new RRule.fromString(event.rrule);
                const occurrence = rule.before(
                    DateTime.fromISO(formattedDate).endOf('day').toJSDate(),
                    true
                );

                return occurrence && DateTime.fromJSDate(occurrence).toFormat('yyyy-MM-dd') === formattedDate;
            }

            return false;

        })

        return filteredEvents
    }

    //Helper to dynamically sync the data when the calendar loaded completely
    async startFilterAfterFirstLoad() {
        await this.waitForFullResponse();
        this.filterUser()
    }
    waitForFullResponse() {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (this.fullResponse) {
                    clearInterval(interval);
                    resolve();
                }
            }, 0);
        });
    }

    filterUser() {
        this.filteredData = this.fullResponse;

        if (this.departmentIdsValue.length > 0) {
            this.filteredData = this.filteredData.filter(item => {
                const departmentIdAsString = String(item.templateValues.departmentId);
                return this.departmentIdsValue.includes(departmentIdAsString);
            });
        }

        if (this.locationIdsValue.length > 0) {
            this.filteredData = this.filteredData.filter(item => {
                const locationIdAsString = String(item.templateValues.locationId);
                return this.locationIdsValue.includes(locationIdAsString);
            });
        }
        this.setCalendarBackgrounds()
        this.removeInactive()
        this.reloadFilteredResources()
    }

    removeInactive() {
        const viewStart = this.calendar.view.activeStart;
        const viewEnd = this.calendar.view.activeEnd;
        this.filteredData = this.filteredData.filter(item => {
            const startDate = new Date(item.templateValues.startDate);
            const endDate = new Date(item.templateValues.endDate);
            return (startDate <= viewEnd && endDate >= viewStart);
        });
    }

    reloadFilteredResources() {
        const resources = this.calendar.getResources();
        resources.forEach(resource => {
            resource.remove();
        });

        this.filteredData.forEach(resource => {
            this.calendar.addResource(resource);
        });
    }

    setCalendarBackgrounds() {
        this.calendar.getEvents().forEach(event => {
            if (event.extendedProps.isBackground) {
                event.remove();
            }
        });

        let minStartDate = new Date(Math.min(...this.filteredData.map(resource => new Date(resource.templateValues.startDate))));
        let maxEndDate = new Date(Math.max(...this.filteredData.map(resource => new Date(resource.templateValues.endDate))));
        minStartDate.setDate(minStartDate.getDate() - 7)
        maxEndDate.setDate(maxEndDate.getDate() + 7)

        this.filteredData.forEach(resource => {
            const startDate = new Date(resource.templateValues.startDate);
            const endDate = new Date(resource.templateValues.endDate);

            const dayBeforeStart = new Date(startDate);
            dayBeforeStart.setDate(dayBeforeStart.getDate() - 1);

            const dayAfterEnd = new Date(endDate);
            dayAfterEnd.setDate(dayAfterEnd.getDate() + 1);

            this.calendar.addEvent({
                start: minStartDate.toISOString(),
                end: dayBeforeStart.toISOString(),
                resourceId: resource.id,
                display: 'background',
                backgroundColor: 'darkgray',
                isBackground: true
            });

            this.calendar.addEvent({
                start: dayAfterEnd.toISOString(),
                end: maxEndDate.toISOString(),
                resourceId: resource.id,
                display: 'background',
                backgroundColor: 'darkgray',
                isBackground: true
            });

        });
    }

    reloadEvents = (event) => {
        this.calendar.refetchEvents()
    }

    /**
     * Event Manipulations
     */

    //When redrag an entry
    updateEntry = (eventData, requestPath) => {
        const requestBody = {
            date: eventData.dateStr,
            contractFunctionId: eventData.resource.id,
            isDragUpdate: true
        };
        const snakeCaseRequestBody = decamelizeKeys(requestBody, { deep: true });
        const response = patch(requestPath, { contentType: "application/json", body: snakeCaseRequestBody })
        if (response.ok) { this.calendar.refetchEvents(); }
    }

    //when drag and drop entry templates
    createEntry = async (snakeCaseRequestBody, calendarEntryType) => {
        if (calendarEntryType === "shift") {
            const requestPath = `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/shifts.json`
            const fetchResponse = await post(requestPath, { contentType: "application/json", body: snakeCaseRequestBody, responseKind: "turbo-stream" })

            if (fetchResponse.ok) {
                const calendarEntryData = await fetchResponse.response.json()
                const camelCasedCalendarEntryData = camelcaseKeys(calendarEntryData, { deep: true })
                this.calendar.refetchEvents()
            }
        } else if (calendarEntryType === "absence") {
            const requestPath = `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/absences/new`
            await get(requestPath, { contentType: "application/json", query: snakeCaseRequestBody, responseKind: "turbo-stream" })
        }
    }

    //On + Button click
    duplicateEntryNextDay(event) {
        const entryData = camelcaseKeys(JSON.parse(event.currentTarget.dataset.calendarEntry), { deep: true })
        const currentDate = new Date(entryData.startDate);
        currentDate.setDate(currentDate.getDate() + 1);
        const nextDate = currentDate.toISOString().split('T')[0];

        const requestBody = {
            duplicate: true,
            date: nextDate,
            contractFunctionId: entryData.contractFunctionId,
            templateId: entryData.templateId,
            shiftId: entryData.shiftId
        }

        const snakeCaseRequestBody = decamelizeKeys(requestBody, { deep: true })
        this.createEntry(snakeCaseRequestBody, "shift")
    }

    //On + Button Drag and drop
    duplicateEntryCol(eventData, templateData) {
        const employeeList = this.calendar.getResources()
        // This step is nessesary because fullcalendar works just with string ids 
        const contractIds = employeeList.map(r => String(r.id)).sort((a, b) => a.localeCompare(b))
        const startIndex = contractIds.indexOf(String(templateData.contractFunctionId));
        const endIndex = contractIds.indexOf(String(eventData.resource.id));

        const originDate = new Date(templateData.startDate).toISOString().split('T')[0];
        const draggedDate = eventData.dateStr;
        const requestBody = {
            bulkCreate: true,
            startIndex: startIndex,
            endIndex: endIndex,
            startDateBulk: originDate,
            endDateBulk: draggedDate,
            contractOrderList: contractIds,
            templateId: templateData.templateId,
            shiftId: templateData.shiftId
        }
        const snakeCaseRequestBody = decamelizeKeys(requestBody, { deep: true })
        this.createEntry(snakeCaseRequestBody, "shift")
    }
}
