import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["hidden", "checkbox"]

    connect() {
        const checkbox = this.checkboxTarget.querySelector('input[type="checkbox"]')
        this.showHiddenField(checkbox.checked)
    }

    toggleCheckbox(event) {
        const checked = event.currentTarget.checked
        this.showHiddenField(checked)
    }

    showHiddenField(show) {
        if (show) {
            this.hiddenTarget.classList.remove("hidden")
        }
        else {
            if (!this.hiddenTarget.classList.contains("hidden")) {
                this.hiddenTarget.classList.add("hidden")
            }
        }
    }
}
