import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["currentTime", "currentDate"]

    connect() {
        this.updateTimeAndDate()
        setInterval(() => this.updateTimeAndDate(), 5000)
    }

    updateTimeAndDate() {
        const now = new Date()

        // Format time as '12:00'
        const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        this.currentTimeTarget.innerText = time

        // Format date as 'Thu, Sep 25'
        const date = now.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
        this.currentDateTarget.innerText = date
    }
}
