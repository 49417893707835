import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["muteButton", "unmuteButton"]
    async connect() {
        await this.setChannelDetailsController()
    }
    setChannelDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    editChat() {
        this.channelDetailsController.editChat()
    }

    deleteChat() {
        this.channelDetailsController.deleteChat()
    }

    async muteChat() {
        await this.channelDetailsController.channel.mute()
        this.muteButtonTarget.classList.add('hidden')
        this.unmuteButtonTarget.classList.remove('hidden')
    }

    async unmuteChat() {
        await this.channelDetailsController.channel.unmute()
        this.muteButtonTarget.classList.remove('hidden')
        this.unmuteButtonTarget.classList.add('hidden')
    }
}
