import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chat"
export default class extends Controller {
    static values = { messageId: String }

    connect() {
        this.setChannelDetailsController()
    }

    setChannelDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsIndexController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    scrollToMessageDesktop() {
        const messageId = this.messageIdValue; // Assuming the input has the message ID
        this.channelDetailsIndexController.scrollToMessage(document.querySelector('#channel-details-messages-desktop'), messageId)
    }

    scrollToMessageMobile() {
        const messageId = this.messageIdValue; // Assuming the input has the message ID
        this.channelDetailsIndexController.scrollToMessage(document.querySelector('#channel-details-messages-mobile'), messageId)
    }
}