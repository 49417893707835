import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../../helpers/mustache_helper"
import { DateTime } from 'luxon'
import { destroy, get } from "@rails/request.js";
import Mark from "mark.js";

export default class extends Controller {
    static targets = [
        'messageInput',
        'mentionWrapper',
        'disabledSendButton',
        'enabledSendButton',
        'currentMessageId',
        'foundMessagesCount',
        'resultsFound',
        'noResults',
        'resultsBar',
        'inputWrapper',
        'muteButton',
        'unmuteButton',
        'currentlyTyping'
    ]

    static values = {
        streamChatChannelId: String,
        groupChatId: String
    }

    async connect() {
        this.setStreamChatClient()
        await this.setChannel()
        this.renderMessages()
        await this.renderReplyHeader()
        this.listenToAndRenderNewMessages()
        this.listenToUsersTyping()
        this.listenToMessageChanges()
        this.listenToSendMessageButton()
        this.updateChatListItemStyle()
        this.uploadedFiles = []
        this.mentionedUsers = []
        this.replyHeaders = document.getElementsByClassName('reply-header')

        this.streamChatClient.on('user.presence.changed', event => {
            this.updateChannelDetailsHeader(event)
        })

        this.streamChatClient.on('reaction.new', event => {
            const { message, reaction } = event;
            this.updateReactions(message)
        })
        this.streamChatClient.on('reaction.updated', event => {
            const { message, reaction } = event;
            this.updateReactions(message)
        })
        this.streamChatClient.on('reaction.deleted', event => {
            const { message, reaction } = event;
            this.updateReactions(message)
        })
    }

    async disconnect() {
        for (const file of this.uploadedFiles) {
            await this.channel.deleteFile(file.asset_url)
        }
        this.channelEventListener.unsubscribe()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async setChannel() {
        await this.chatIndexController.setCurrentChat(this.streamChatChannelIdValue);
        this.channel = await this.chatIndexController.streamChatController.fetchChannelById(this.streamChatChannelIdValue);
        this.chatIndexController.currentChannel = this.channel
    }

    async renderMessages() {
        // Get the messages from the channel
        let messages = this.channel.state.messageSets[0].messages.slice();

        const unreadCount = this.channel.state.unreadCount;
        const firstUnreadIndex = messages.length - unreadCount;

        let lastRenderedDate = null; // Track the last rendered date

        // Render messages
        for (const [index, message] of messages.entries()) {
            const currentDate = new Date(message.created_at);

            // Insert a date divider only if the date changes
            if (!lastRenderedDate || lastRenderedDate.toDateString() !== currentDate.toDateString()) {
                this.insertDateDivider(currentDate);
                lastRenderedDate = currentDate; // Update the last rendered date
            }

            if (index === firstUnreadIndex) {
                // Insert a divider at the first unread message
                this.insertUnreadDivider();
            }

            // Wait for renderMessage to complete before continuing
            await this.renderMessage(message);
        }

        // Mark messages as read and update the unread count
        this.channel.markRead({ user_id: this.streamChatClient.user.id });
        this.chatIndexController.streamChatController.updateUnreadMessageCount();
    }

    replyMustache(message, messageId) {
        let replyElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--reply-header',
            {
                isForwardedMessage: true,
                messageId: `mid_${messageId}`,
                hasMessage: message.message.text !== '',
                fullName: message.message.user.name,
                message: message.message.text,
                hasAttachments: message.message.attachments.length > 0,
                hasMultipleAttachments: message.message.attachments.length > 1,
                attachmentCount: message.message.attachments.length,
                attachmentName: message.message.attachments.length === 0 ? '' : message.message.attachments[0].title
            },
            [],
            ['---', '___']
        );
        return replyElement
    }


    async renderReplyHeader() {
        if (this.chatIndexController.repliedMessages.length > 0 || this.chatIndexController.forwardedMessageId !== null) {
            const repliedMessage = this.chatIndexController.repliedMessages.find(item => item.channelId === this.channel.id)
            let replyElement
            if (this.chatIndexController.forwardedMessageId) {
                //If forward message
                const message = await this.chatIndexController.getMessageById(this.chatIndexController.forwardedMessageId)
                replyElement = this.replyMustache(message, this.chatIndexController.forwardedMessageId)
            } else if (repliedMessage) {
                //if reply
                const message = await this.chatIndexController.getMessageById(repliedMessage.messageId)
                replyElement = this.replyMustache(message, repliedMessage.messageId)
            }
            if (this.replyHeaders && replyElement) {
                for (let i = 0; i < this.replyHeaders.length; i++) {
                    const target = this.replyHeaders[i];

                    if (target.hasChildNodes()) {
                        // Replace the first child if it exists
                        target.replaceChild(replyElement, target.firstChild);
                    } else {
                        // Append the reply element if no child exists
                        const cloneDiv = replyElement.cloneNode(true)
                        target.appendChild(cloneDiv);
                    }
                }
            }

        }
    }

    async renderMessage(message) {
        let placeholderElements = document.getElementsByClassName('channel-details-placeholder')
        placeholderElements[0]?.remove()
        placeholderElements[0]?.remove()

        const repliedMessage = message.parent_id !== undefined ? await this.chatIndexController.getMessageById(message.parent_id) : null

        const forwardedMessage = message.attachments.find(attachment => attachment.type === 'forwardMessage')

        const forwardedMessageInfo = forwardedMessage !== undefined ? await this.chatIndexController.getMessageById(forwardedMessage.messageId) : null

        // Render the main message template
        const messageElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--message',
            {
                isLoggedInDevice: (this.chatIndexController.streamChatController.deviceLoggedInValue && this.chatIndexController.streamChatController.isConnectAppValue) || !this.chatIndexController.streamChatController.isConnectAppValue,
                initials: [message.user.firstname.charAt(0), message.user.lastname.charAt(0)].join(''),
                senderName: message.user.name,
                sentAt: this.chatIndexController.messageSentAt(message).toLocaleString(DateTime.TIME_24_SIMPLE),
                message: this.highlightMentions(message),
                messageId: `mid_${message.id}`,
                taskAssignees: message.user.id === this.chatIndexController.streamChatUserIdValue ? [message.user.id] : [message.user.id, this.chatIndexController.streamChatUserIdValue],
                isOwnMessage: this.streamChatClient.user.id === message.user.id,
                parentId: message.parent_id,
                hasRepliesOrQuotes: repliedMessage !== undefined || repliedMessage !== null,
                isForwarded: forwardedMessageInfo !== null,
                repliedFullName: forwardedMessageInfo !== null ? forwardedMessageInfo.message.user.name : (repliedMessage !== null ? repliedMessage.message.user.name : null),
                repliedMessage: forwardedMessageInfo !== null ? forwardedMessageInfo.message.html : (repliedMessage !== null ? repliedMessage.message.html : null)
            }
        );

        if (forwardedMessageInfo !== null) {
            if (forwardedMessageInfo.message.attachments && forwardedMessageInfo.message.attachments.length > 0) {
                forwardedMessageInfo.message.attachments
                    .filter(attachment => attachment.type !== 'forwardMessage')
                    .forEach(attachment => {
                        const attachmentElement = MustacheHelper.toElement(
                            'components--employee-zone--chat--channel-details--index--mustache-template--attached-file',
                            {
                                fileName: attachment.title,
                                fileUrl: attachment.asset_url,
                                fileSize: this.numberToHumanSize(attachment.file_size)
                            },
                            [],
                            ['---', '___']
                        );
                        // Append each attachment to the message element
                        messageElement.querySelector('#attached-replied-files-wrapper').appendChild(attachmentElement);
                    });
            }
        }

        if (repliedMessage !== null) {
            if (repliedMessage.message.attachments && repliedMessage.message.attachments.length > 0) {
                repliedMessage.message.attachments.forEach(attachment => {
                    const attachmentElement = MustacheHelper.toElement(
                        'components--employee-zone--chat--channel-details--index--mustache-template--attached-file',
                        {
                            fileName: attachment.title,
                            fileUrl: attachment.asset_url,
                            fileSize: this.numberToHumanSize(attachment.file_size)
                        },
                        [],
                        ['---', '___']
                    );
                    // Append each attachment to the message element
                    messageElement.querySelector('#attached-replied-files-wrapper').appendChild(attachmentElement);
                });
            }
        }

        // Check if the message has attachments
        if (message.attachments && message.attachments.length > 0) {
            const validAttachments = message.attachments.filter(attachment => attachment.type !== 'forwardMessage');
            // Loop through each attachment and render its template
            validAttachments.forEach(attachment => {
                const attachmentElement = MustacheHelper.toElement(
                    'components--employee-zone--chat--channel-details--index--mustache-template--attached-file',
                    {
                        fileName: attachment.title,
                        fileUrl: attachment.asset_url,
                        fileSize: this.numberToHumanSize(attachment.file_size)
                    },
                    [],
                    ['---', '___']
                );
                // Append each attachment to the message element
                messageElement.querySelector('#attached-files-wrapper').appendChild(attachmentElement);
            });
        }


        for (const messageElementElement of document.getElementsByClassName('channel-details-messages')) {
            const cloneDiv = messageElement.cloneNode(true)
            messageElementElement.prepend(cloneDiv);
            await this.addReactions(message, cloneDiv)
        }
    }

    highlightMentions(message) {
        if (!message || !message.mentioned_users || !message.html) {
            return message.html; // Return unchanged if required data is missing
        }

        let updatedHtml = message.html;

        // Loop through each mentioned user
        message.mentioned_users.forEach(user => {
            const userName = user.name;

            // Match the username after an @ and replace it with a span
            const mentionRegex = new RegExp(`@${userName}`, 'g');
            updatedHtml = updatedHtml.replace(
                mentionRegex,
                `<span class="text-primary font-medium text-lg cursor-pointer">${userName}</span>`
            );
        });

        return updatedHtml;
    }

    async updateReactions(message) {
        const mobileChatMessageWrapper = document.getElementById('channel-details-messages-mobile')
        const webChatMessageWrapper = document.getElementById('channel-details-messages-desktop')
        const mobileMessageElement = mobileChatMessageWrapper.querySelector("#mid_" + message.id)
        const webMessageElement = webChatMessageWrapper.querySelector("#mid_" + message.id)

        // messageElement.querySelector('#reactions-wrapper').innerHTML = ''
        await this.addReactions(message, mobileMessageElement)
        await this.addReactions(message, webMessageElement)
    }

    async addReactions(message, messageElement) {
        const reactionsWrapper = messageElement.querySelector('#reactions-wrapper');

        // Check if there are no reaction counts
        if (!message.reaction_counts || Object.keys(message.reaction_counts).length === 0) {
            return; // Nothing to display
        }

        // Fetch reactions for the message
        const reactions = await this.channel.getReactions(message.id);

        // Group reactions by type and calculate aggregate data
        const groupedReactions = reactions.reactions.reduce((acc, reaction) => {
            if (!acc[reaction.type]) {
                acc[reaction.type] = {
                    type: reaction.type,
                    emoji: reaction.emoji,
                    score: 0,
                    ownReaction: false,
                };
            }
            acc[reaction.type].score += reaction.score;
            acc[reaction.type].ownReaction = acc[reaction.type].ownReaction || this.checkOwnReaction(message.own_reactions, reaction.type);
            return acc;
        }, {});

        // Clear reactionsWrapper
        reactionsWrapper.innerHTML = '';
        // Render each grouped reaction
        Object.values(groupedReactions).forEach((reaction) => {
            const reactionElement = MustacheHelper.toElement(
                'components--employee-zone--chat--channel-details--index--mustache-template--reaction',
                {
                    messageId: message.id,
                    reactionType: reaction.type,
                    reactionCount: reaction.score,
                    emoji: reaction.emoji,
                    ownReaction: reaction.ownReaction
                },
                [],
                ['---', '___']
            );

            // Append reaction to the wrapper
            reactionsWrapper.appendChild(reactionElement);
        });
    }


    checkOwnReaction(ownReactions, reactionType) {
        return ownReactions.some(ownReaction => ownReaction.type === reactionType);
    }

    insertUnreadDivider() {
        const dividerElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--unread-divider'
        );

        for (const messageElementElement of document.getElementsByClassName('channel-details-messages')) {
            const cloneDiv = dividerElement.cloneNode(true)
            messageElementElement.prepend(cloneDiv);
        }
    }

    insertDateDivider(date) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const currentYear = today.getFullYear();
        const messageYear = date.getFullYear();

        // Determine if the year should be included in the formatted date
        const options = messageYear === currentYear
            ? { month: "short", day: "numeric" }
            : { year: "numeric", month: "short", day: "numeric" };

        const dividerElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--date-divider',
            {
                isToday: date.toDateString() === today.toDateString(),
                isYesterday: date.toDateString() === yesterday.toDateString(),
                date: date.toISOString().split("T")[0],
                formattedDate: date.toLocaleDateString("en-US", options)
            }
        );

        // Append the divider to all message containers
        for (const messageContainer of document.getElementsByClassName('channel-details-messages')) {
            messageContainer.prepend(dividerElement.cloneNode(true));
        }
    }

    listenToAndRenderNewMessages() {
        this.channelEventListener = this.channel.on('message.new', async event => {
            const newMessageDate = new Date(event.message.created_at);
            const dateId = `date-channel-divider-${newMessageDate.toISOString().split("T")[0]}`;

            // Check if the divider for this date already exists
            if (!document.getElementById(dateId)) {
                this.insertDateDivider(newMessageDate); // Insert the date divider if not present
            }

            // Render the new message
            await this.renderMessage(event.message);

            // Mark the message as read and update the unread count
            this.channel.markRead({ user_id: this.streamChatClient.user.id });
        });
    }

    listenToUsersTyping() {
        this.currentlyTypingUsers = []
        this.channel.on('typing.start', event => {
            // Check if the typing user is not the current user
            if (event.user.id !== this.streamChatClient.user.id && !this.currentlyTypingUsers.some(user => user.id === event.user.id)) {
                this.currentlyTypingUsers.push({ id: event.user.id, firstname: event.user.firstname });
                this.renderCurrentlyTyping();
            }
        })

        this.channel.on('typing.stop', event => {
            // Remove the user from currentlyTypingUsers by matching their id
            this.currentlyTypingUsers = this.currentlyTypingUsers.filter(user => user.id !== event.user.id);
            this.renderCurrentlyTyping();
        })
    }

    renderCurrentlyTyping() {
        // Filter out the current user before generating the output string
        const otherTypingUsers = this.currentlyTypingUsers.filter(user => user.id !== this.streamChatClient.user.id);
        const userNames = otherTypingUsers.map(user => user.firstname);

        let outputString = this.joinWithCommasAnd(userNames);
        if (outputString) {
            outputString += " typing..."; // TODO: replace with i18n
        }
        this.currentlyTypingTargets.forEach(target => {
            target.innerText = outputString
        })
    }

    joinWithCommasAnd(arr) {
        if (arr.length === 0) {
            return ""; // Return empty for an empty array
        } else if (arr.length === 1) {
            return arr[0]; // Return the only item for a single-item array
        } else if (arr.length === 2) {
            return arr.join(" and "); // Join with "and" if there are only two items TODO: replace with i18n
        } else {
            return arr.slice(0, -1).join(", ") + ", and " + arr[arr.length - 1];
        }
    }

    async listenToMessageChanges() {
        for (let i = 0; i < this.messageInputTargets.length; i++) {
            // Initial state check for enabling/disabling buttons
            this.checkSendButtonState(i);

            this.messageInputTargets[i].addEventListener('keyup', (event) => {
                this.checkSendButtonState(i);

                // Listen for 'Enter' key press (without Shift) to send message
                if (
                    event.key === 'Enter' &&
                    !event.shiftKey &&
                    (this.messageInputTargets[i].value.trim() !== '' || (this.uploadedFiles && this.uploadedFiles.length > 0)) &&
                    !this.chatIndexController.streamChatController.isNativeDeviceValue
                ) {
                    event.preventDefault(); // Prevent default 'Enter' key behavior (e.g., new line)
                    this.sendMessage(i); // Send the message when 'Enter' is pressed
                }
            });
        }
    }

    // New method to check send button state
    async checkSendButtonState(index) {
        const inputNotEmpty = this.messageInputTargets[index].value.trim() !== '';
        const filesUploaded = this.uploadedFiles && this.uploadedFiles.length > 0;
        const repliedMessage = await this.chatIndexController.repliedMessages.find(item => item.channelId === this.channel.id)
        const forwardedMessage = await this.chatIndexController.forwardedMessageId

        if (!inputNotEmpty && !filesUploaded && repliedMessage === undefined && forwardedMessage === null) {
            this.disabledSendButtonTargets[index].classList.remove('hidden');
            this.enabledSendButtonTargets[index].classList.add('hidden');
        } else {
            this.disabledSendButtonTargets[index].classList.add('hidden');
            this.enabledSendButtonTargets[index].classList.remove('hidden');
        }
    }


    listenToSendMessageButton() {
        for (let i = 0; i < this.enabledSendButtonTargets.length; i++) {
            this.enabledSendButtonTargets[i].addEventListener('click', () => {
                this.sendMessage(i)
            })
        }
    }

    removeUnreadDivider() {
        const dividerElement = document.getElementById('unread-channel-divider')
        if (dividerElement) {
            dividerElement.remove()
        }
    }

    async onInput(event) {
        await this.channel.keystroke();

        const input = event.target;
        const cursorPosition = input.selectionStart;
        const value = input.value;

        // Check if '@' exists in the input text before the cursor
        const match = value.slice(0, cursorPosition).match(/@(\w*)$/);
        if (match) {
            const searchTerm = match[1];

            if (searchTerm.length > 0) {
                // Query StreamChat for users matching the search term
                const users = await this.fetchUsers(searchTerm);

                // Display dropdown near the cursor
                this.showMentionDropdown(users, input, cursorPosition);
            }
        } else {
            this.hideMentionDropdown();
        }
    }

    async fetchUsers(searchTerm) {
        try {
            if (!searchTerm.trim()) {
                return [];
            }

            const result = await this.channel.queryMembers(
                { name: { "$autocomplete": searchTerm } },
                {},
                {}
            );

            return result.members;
        } catch (error) {
            console.error("Error fetching users:", error);
            return [];
        }
    }

    showMentionDropdown(users, input, cursorPosition) {
        this.mentionWrapperTargets.forEach((dropdown) => {
            dropdown.innerHTML = "";

            users.forEach((user) => {
                const profilePicturePath = this.chatIndexController.getProfilePicturePath(this.channel, [user.user])

                const mentionSuggestion = MustacheHelper.toElement(
                    'components--employee-zone--chat--channel-details--index--mustache-template--mention-suggestion',
                    {
                        isActive: user.user.online,
                        fullName: user.user.name,
                        profilePicturePath: profilePicturePath,
                        hasProfilePicture: profilePicturePath !== '' && profilePicturePath !== '#',
                        initials: this.chatIndexController.generateInitials(user.user.name)
                    },
                    [],
                    ['---', '___']
                );

                mentionSuggestion.addEventListener("click", () => this.insertMention(user.user, input));
                dropdown.appendChild(mentionSuggestion);
            });

            const { top, left } = this.getCursorCoordinates(input, cursorPosition);

            dropdown.style.top = `${top - dropdown.offsetHeight}px`; // Position above the cursor
            dropdown.style.left = `${left}px`;
            dropdown.style.display = "block";
        })
    }

    hideMentionDropdown() {
        this.mentionWrapperTargets.forEach((dropdown) => {
            dropdown.style.display = "none";
        });
    }

    insertMention(user, input) {
        const cursorPosition = input.selectionStart;
        const value = input.value;

        // Replace "@searchTerm" with "@username "
        const match = value.slice(0, cursorPosition).match(/@(\w*)$/);
        if (match) {
            const start = match.index;
            const mentionText = `@${user.name}`;

            // Insert the mention with the username
            const newValue = value.slice(0, start) + mentionText + value.slice(cursorPosition);

            // Set the value with the mention text
            input.value = newValue;

            // Move the cursor to the end of the inserted mention
            input.setSelectionRange(start + mentionText.length, start + mentionText.length);

            if (!this.mentionedUsers.some(existingUser => existingUser.id === user.id)) {
                this.mentionedUsers.push({ id: user.id, name: user.name });
            }

            // Hide the mention dropdown after insertion
            this.hideMentionDropdown();
        }
    }

    getCursorCoordinates(input, cursorPosition) {
        const { offsetLeft, offsetTop, scrollTop } = input;

        const dummyDiv = document.createElement("div");
        const textBeforeCursor = input.value.substring(0, cursorPosition);

        dummyDiv.style.position = "absolute";
        dummyDiv.style.visibility = "hidden";
        dummyDiv.style.whiteSpace = "pre-wrap";
        dummyDiv.style.wordWrap = "break-word";
        dummyDiv.style.font = getComputedStyle(input).font;
        dummyDiv.style.lineHeight = getComputedStyle(input).lineHeight;
        dummyDiv.textContent = textBeforeCursor;

        document.body.appendChild(dummyDiv);

        const { offsetWidth, offsetHeight } = input;
        dummyDiv.style.width = `${offsetWidth}px`;
        dummyDiv.style.height = `${offsetHeight}px`;

        const rect = dummyDiv.getBoundingClientRect();
        const coordinates = {
            top: rect.bottom + offsetTop - scrollTop,
            left: rect.left + offsetLeft,
        };

        document.body.removeChild(dummyDiv);
        return coordinates;
    }



    async sendMessage(number) {
        await this.channel.stopTyping();
        if (this.messageInputTargets[number].value === '') {
            return
        }
        this.removeUnreadDivider()
        let body;
        if (this.uploadedFiles !== []) {
            body = {
                text: this.messageInputTargets[number].value,
                attachments: this.uploadedFiles.map(file => ({
                    type: file.type,
                    asset_url: file.asset_url,
                    title: file.title,
                    file_size: file.size
                }))
            }
        } else {
            body = { text: this.messageInputTargets[number].value }
        }
        const repliedMessage = this.chatIndexController.repliedMessages.find(item => item.channelId === this.channel.id)
        if (repliedMessage) {
            body.parent_id = repliedMessage.messageId
            body.show_in_channel = true
        }
        if (this.chatIndexController.forwardedMessageId) {
            //attach a forwarded message to the attachments
            body.attachments.push({ type: "forwardMessage", messageId: this.chatIndexController.forwardedMessageId })
        }
        if (this.mentionedUsers !== []) {
            body.mentioned_users = this.filterMentionedUsers(this.messageInputTargets[number].value, this.mentionedUsers)
        }
        this.channel.sendMessage(body);

        this.messageInputTargets[number].value = ''; // Clear the input field
        for (const fileWrapper of document.getElementsByClassName('file-wrapper')) {
            fileWrapper.innerHTML = '';
        }
        this.uploadedFiles = []

        this.disabledSendButtonTargets[number].classList.remove('hidden'); // Disable send button
        this.enabledSendButtonTargets[number].classList.add('hidden');     // Hide enabled button

        if (repliedMessage) {
            await this.removeHeader(repliedMessage.messageId)
        }
        if (this.chatIndexController.forwardedMessageId) {
            await this.removeHeader(this.chatIndexController.forwardedMessageId)
        }
        this.listenToMessageChanges()
    }

    filterMentionedUsers(text, users) {
        // Extract all full strings after @ in the text (case-insensitive)
        const mentionRegex = /@([a-zA-Z\s]+)/g; // Matches full names (letters and spaces)
        const mentionedStrings = [];
        let match;

        while ((match = mentionRegex.exec(text)) !== null) {
            mentionedStrings.push(match[1].trim().toLowerCase()); // Collect all strings, trim spaces, and make lowercase
        }

        // Filter users to check if their name appears in any mentioned string
        const filteredUsers = users.filter(user =>
            mentionedStrings.some(mentionedText => mentionedText.includes(user.name.toLowerCase()))
        );

        // Map to get only the IDs of the matching users
        const userIds = filteredUsers.map(user => user.id);

        return userIds;
    }

    async removeHeader(messageId) {
        await this.chatIndexController.removeMessageAssociations(messageId)

        for (let i = 0; i < this.replyHeaders.length; i++) {
            const target = this.replyHeaders[i];

            if (target.hasChildNodes()) {
                target.children[0].classList.add("transition-opacity", "duration-500", "opacity-0");
                setTimeout(() => {
                    target.children[0].remove();
                }, 500);
            }
        }
    }

    updateChatListItemStyle() {
        let chatListItems = document.getElementsByClassName('chat-list-items')
        for (let i = 0; i < chatListItems.length; i++) {
            chatListItems[i].classList.remove('bg-canvas-white', 'border-l-primary', 'selected-chat')
            chatListItems[i].style = ''
        }
        let selectedChatListItem = document.getElementById(this.streamChatChannelIdValue)
        selectedChatListItem.classList.add('bg-canvas-white', 'border-l-primary', 'selected-chat')
        selectedChatListItem.style = 'border-left-width: 3px;'
    }

    async updateChannelDetailsHeader() {
        const response = await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/channel_details/user_presence?stream_chat_channel_id=${this.chatIndexController.currentChannel.id}`, { responseKind: "turbo-stream" })
        if (response.ok) {
            this.chatIndexController.reloadChannelList();
        }
    }

    hideChatSidebar() {
        let channelDetailsWrappers = document.getElementsByClassName('channel-details-content')
        let mobileChannelSidebar = document.getElementById("employee-zone--chat--index--channel-sidebars-mobile")
        let desktopChannelSidebar = document.getElementById("employee-zone--chat--index--channel-sidebars-desktop")

        for (let i = 0; i < channelDetailsWrappers.length; i++) {
            channelDetailsWrappers[i].classList.remove('hidden')
        }
        mobileChannelSidebar.classList.add('hidden')
        desktopChannelSidebar.classList.add('hidden')
    }



    async onFileUpload(event) {
        const uploadedFiles = event.target.files
        for (let i = 0; i < uploadedFiles.length; i++) {
            const response = await this.channel.sendFile(uploadedFiles[i])
            this.uploadedFiles.push({
                type: 'file',
                asset_url: response.file,
                title: uploadedFiles[i].name,
                size: uploadedFiles[i].size
            })
            this.displayUploadedFile(uploadedFiles[i], response.file)
        }
        this.listenToMessageChanges()
    }

    displayUploadedFile(file, filePath) {
        const attachmentElement = MustacheHelper.toElement(
            'components--employee-zone--chat--channel-details--index--mustache-template--uploaded-file',
            {
                fileName: file.name,
                fileSize: this.numberToHumanSize(file.size),
                fileUrl: filePath
            }
        )
        for (const fileWrapper of document.getElementsByClassName('file-wrapper')) {
            const cloneDiv = attachmentElement.cloneNode(true)
            fileWrapper.prepend(cloneDiv);
        }
    }

    async removeFile(event) {
        const assetUrl = event.target.getAttribute('data-file-url')
        const deletionSuccessful = await this.channel.deleteFile(assetUrl)
        if (deletionSuccessful) {
            this.uploadedFiles = this.uploadedFiles.filter(file => file.asset_url !== assetUrl);
            event.target.closest('.wrapper').remove()
        }
    }

    numberToHumanSize(size) {
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (size === 0) return '0 Bytes';

        const index = Math.floor(Math.log(size) / Math.log(1024));
        const sizeInUnit = size / Math.pow(1024, index);
        return `${sizeInUnit.toFixed(2)} ${units[index]}`;
    }

    // Channel Actions
    async muteChat() {
        await this.channel.mute()
        for (let i = 0; i < this.muteButtonTargets.length; i++) {
            this.muteButtonTargets[i].classList.add('hidden')
            this.unmuteButtonTargets[i].classList.remove('hidden')
        }

    }

    async unmuteChat() {
        await this.channel.unmute()
        for (let i = 0; i < this.muteButtonTargets.length; i++) {
            this.muteButtonTargets[i].classList.remove('hidden')
            this.unmuteButtonTargets[i].classList.add('hidden')
        }
    }

    archiveChat() {
        console.log('archive')
    }

    async editChat() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/chat/${this.groupChatIdValue}/edit?stream_chat_channel_id=${this.streamChatChannelIdValue}`, { responseKind: "turbo-stream" })
    }

    async deleteChat() {
        await destroy(`/${window.currentWorkspaceSlug}/employee_zone/chat/${this.streamChatChannelIdValue}`, { responseKind: "turbo-stream" })
    }

    async onSearchInput() {
        const searchTerm = this.element.querySelector('input').value.toLowerCase();
        let filteredChatMessages = await this.chatIndexController.fetchMessages(searchTerm, this.channel.id);

        this.sortedMessages = filteredChatMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        if (this.sortedMessages.length > 0) {
            this.noResultsTarget.classList.add('hidden')
            this.resultsFoundTarget.classList.remove('hidden')
            this.currentMessageIdTarget.innerText = 1
            this.foundMessagesCountTarget.innerText = this.sortedMessages.length
        } else {
            this.noResultsTarget.classList.remove('hidden')
            this.resultsFoundTarget.classList.add('hidden')
        }

        this.channelMessagesWrapper = document.getElementById('channel-details-messages-mobile')

        this.mark = new Mark(this.channelMessagesWrapper.querySelectorAll('.chat-message-text'));
        this.mark.unmark()
        this.mark.mark(searchTerm, {
            className: 'bg-blue-light'
        });
    }

    async nextResult() {
        let nextNumber = Number(this.currentMessageIdTarget.innerText) + 1
        let result = nextNumber > Number(this.foundMessagesCountTarget.innerText) ? 1 : nextNumber
        this.currentMessageIdTarget.innerText = result
        this.scrollToMessage(this.channelMessagesWrapper, await this.sortedMessages[result - 1].id)
    }

    async lastResult() {
        let nextNumber = Number(this.currentMessageIdTarget.innerText) - 1
        let result = nextNumber > 0 ? nextNumber : this.foundMessagesCountTarget.innerText
        this.currentMessageIdTarget.innerText = result
        this.scrollToMessage(this.channelMessagesWrapper, await this.sortedMessages[result - 1].id)
    }

    scrollToMessage(containerElement, messageId) {

        if (!containerElement || !containerElement.contains(containerElement.querySelector(`#mid_${messageId}`))) {
            console.error("Message not found in the desktop message list");
            return;
        }

        const messageElement = containerElement.querySelector(`#mid_${messageId}`);

        if (messageElement) {
            // Calculate the offset position of the message relative to the container
            const messageOffsetTop = messageElement.offsetTop - containerElement.offsetTop;

            // Scroll the container to bring the message into view
            containerElement.scrollTo({
                top: messageOffsetTop - containerElement.clientHeight / 2, // Center the message within the container
                behavior: "smooth"
            });

            // Optionally, you can add a highlight effect here
            messageElement.style.transition = "background-color 1s ease";
            messageElement.classList.add("bg-canvas-light");
            setTimeout(() => {
                messageElement.style.backgroundColor = "transparent"; // Fade to transparent
            }, 1000); // Wait 1 second before starting fade out

            // Cleanup after the fade out completes
            setTimeout(() => {
                messageElement.style.transition = ""; // Reset transition
                messageElement.style.backgroundColor = "";
                messageElement.classList.remove("bg-canvas-light"); // Clear background color
            }, 2000); // Remove styles after 2 seconds
        } else {
            console.error("Message element not found");
        }
    }

    closedStyles() {
        if (this.mark) {
            this.mark.unmark()
        }
        document.getElementById('channel-search-mobile-bar').remove()
        this.resultsBarTarget.remove()
        this.inputWrapperTarget.classList.remove('hidden')
    }

    async sendReaction(messageId, type, emoji) {
        await this.channel.sendReaction(messageId, {
            type: type, emoji: emoji
        })
        const message = await this.chatIndexController.getMessageById(messageId)
        this.updateReactions(message.message)
    }

    async removeReaction(messageId, reactionType) {
        await this.channel.deleteReaction(messageId, reactionType);
        const message = await this.chatIndexController.getMessageById(messageId)
        this.updateReactions(message.message)
    }
}
