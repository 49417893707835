import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";
import {get} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "dropdownMenu", "notificationBtn" ]

    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
    }

   async toggleDropdown() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/notifications`, { responseKind: "turbo-stream" })
    }

    showDropdown() {
        this.dropdownMenuTarget.classList.remove("hidden");
        enter(this.dropdownMenuTarget);
    }

    hideDropdown() {
        this.dropdownMenuTarget.classList.add('hidden')
        leave(this.dropdownMenuTarget)
    }

    outsideClick(event) {
        if (!this.element.contains(event.target) && !this.dropdownMenuTarget.classList.contains('hidden')) {
            this.hideDropdown();
        }
    }
}
