import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    turboFrameId: String
  }

  connect() {
    const element = document.getElementById(this.turboFrameIdValue)
    if (element) { element.reload() }
  }
}