import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "timeRange"];

    connect() {
        this.syncInputs()
    }

    findElements() {
        this.startTime = document.getElementById('calendar_entry_start_time')
        this.endTime = document.getElementById('calendar_entry_end_time')
        this.startTimeHour = document.getElementById('calendar_entry_start_time_hour')
        this.endTimeHour = document.getElementById('calendar_entry_end_time_hour')
        this.startTimeMinute = document.getElementById('calendar_entry_start_time_minute')
        this.endTimeMinute = document.getElementById('calendar_entry_end_time_minute')
    }

    syncInputs() {
        const checkbox = this.checkboxTarget.querySelector('input[type="checkbox"]');
        if (checkbox.checked) {
            this.setTimeInputs(checkbox.checked)
        }
    }

    onChange(event) {
        this.setTimeInputs(event.target.checked)
    }

    setTimeInputs(checked) {
        this.findElements()

        this.startTime.value = checked ? '00:00' : this.startTimeHour.value + ':' + this.startTimeMinute.value;
        this.endTime.value = checked ? '23:59' : this.endTimeHour.value + ':' + this.endTimeMinute.value;

        const timeInputs = [this.startTimeHour, this.startTimeMinute, this.endTimeHour, this.endTimeMinute]
        timeInputs.forEach(input => {
            input.disabled = checked
            checked ? input.classList.add('text-ink-disabled', 'bg-transparent', 'border-transparent') : input.classList.add('text-black', 'bg-canvas-light')
            checked ? input.classList.remove('text-black', 'bg-canvas-light') : input.classList.remove('text-ink-disabled', 'bg-transparent', 'border-transparent')
        })
    }
}
