import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["status", "endDate", "assignee"]

    resetInputs() {
        this.resetDropdowns(this.statusTarget)
        this.resetDropdowns(this.assigneeTarget)
        this.resetDate()
    }

    resetDropdowns(parent) {
        const dropdown = parent.querySelector("#dropdown-menu")
        if (dropdown != null) {
            const checkboxes = dropdown.querySelectorAll('input[type="checkbox"]')
            checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    checkbox.checked = false
                    checkbox.dispatchEvent(new Event('click', { bubbles: true }));
                }
            });
        }
    }

    resetDate() {
        const input = this.endDateTarget.querySelector("#visible_end_date_input")
        const datepicker = this.endDateTarget.querySelector('#components--form--datepicker')
        const datepickerController = this.application.getControllerForElementAndIdentifier(
            datepicker,
            'components--form--datepicker'
        )
        datepickerController.startInputTarget.value = null
        input.value = ""
    }
}
