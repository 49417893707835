import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js";
import { Picker } from "emoji-mart";

export default class extends Controller {
    static values = { taskAssignees: String, messageId: String, isMobile: Boolean }
    static targets = ['message', 'showMoreButton', 'showLessButton', 'repliedContent', "pickerWrapper"]

    connect() {
        this.picker = null; // Initialize picker reference
        this.setStreamChatClient()
        if (this.hasRepliedContentTarget) {
            if (this.repliedContentTarget.scrollHeight > this.repliedContentTarget.offsetHeight) {
                this.showMoreButtonTarget.classList.remove("hidden");
            }
        }
    }

    toggle() {
        // Toggle visibility
        this.repliedContentTarget.classList.toggle("line-clamp-2");
        this.showMoreButtonTarget.classList.toggle("hidden");
        this.showLessButtonTarget.classList.toggle("hidden");
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.setChannelDetailsController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setChannelDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    reactToMessage() {
        if (!this.picker) {
            this.openEmojiPicker();
        } else {
            this.closeEmojiPicker();
        }
    }

    openEmojiPicker() {
        // If the picker already exists, ensure it's fully cleared
        this.closeEmojiPicker();

        this.picker = new Picker({
            onEmojiSelect: (emoji) => {
                this.reactMessage(emoji.id, emoji.native)
                this.closeEmojiPicker();
            },
            onClickOutside: (event) => {
                if (event.target.closest('span') === null || !event.target.closest('span').classList.contains('reaction-button')) {
                    this.closeEmojiPicker();
                }
            },
            theme: "light",
            autoFocus: true,
        });

        // Append the picker to the correct wrapper
        const wrapperId = this.isMobileValue ? 'emoji-picker-wrapper-mobile' : 'emoji-picker-wrapper';
        const wrapper = document.getElementById(wrapperId);

        if (wrapper) {
            wrapper.appendChild(this.picker);
        } else {
            console.error(`Error: #${wrapperId} element not found!`);
        }
    }

    closeEmojiPicker() {
        if (this.picker) {
            this.picker.remove(); // Remove the picker from the DOM
            this.picker = null; // Reset the picker instance
        }
    }

    getEmojiById(emojiId) {
        return this.picker.props.emojiData.find(e => e.id === emojiId)
    }

    reactMessage(messageType, emoji) {
        this.channelDetailsController.sendReaction(this.messageIdValue.split('_')[1], messageType, emoji)
    }

    replyToMessage() {
        const messageWrapper = this.element.closest('.chat-message-wrapper')
        const messageId = messageWrapper.id.split('_')[1]
        this.chatIndexController.removeMessageAssociations(messageId);
        this.chatIndexController.repliedMessages.push({ messageId: messageId, channelId: this.channelDetailsController.channel.id })
        this.channelDetailsController.renderReplyHeader()
        this.channelDetailsController.listenToMessageChanges()
    }

    forwardMessage(plainId) {
        const messageId = plainId.split('_')[1]
        this.chatIndexController.removeMessageAssociations(messageId);
        this.chatIndexController.forwardedMessageId = messageId
        this.channelDetailsController.renderReplyHeader()
        this.channelDetailsController.listenToMessageChanges()
    }

    saveForLater() {
        //TODO
    }

    async createTask() {
        let messageWrapper = this.element.closest('.chat-message-wrapper')

        const now = new Date();
        // Start time (current time in HH:mm)
        const startTime = now.toTimeString().slice(0, 5);

        // End time set to 23:59
        const endTime = "23:59";

        // Start date (today's date in YYYY-MM-DD)
        const startDate = now.toISOString().split('T')[0];

        // End date (one week from today)
        const endDate = new Date(now);
        endDate.setDate(endDate.getDate() + 7);
        const formattedEndDate = endDate.toISOString().split('T')[0];

        let userIds = this.taskAssigneesValue.split(',')

        let departmentIds = []
        for (let i = 0; i < userIds.length; i++) {
            if (userIds[i] !== '') {
                let response = await get(`/${window.currentWorkspaceSlug}/employee_zone/employees/departments_of_employees?employee_id=${userIds[i]}`)
                departmentIds.push(...await response.json)
            } else {
                userIds.pop(i, 1)
            }
        }

        let body = {
            calendar_entry: {
                title: messageWrapper.querySelector('.chat-message-text').innerText,
                start_time: startTime,
                end_time: endTime,
                start_date: startDate,
                end_date: formattedEndDate,
                employee_ids: employeeIds,
                department_ids: [this.findCommonOrFallback(departmentIds[0], departmentIds[1])],
                task_attributes: {
                    shift_id: ''
                }
            }
        }

        await post(`/${window.currentWorkspaceSlug}/employee_zone/tasks/`, {
            responseKind: "turbo-stream",
            body: body
        })
    }

    findCommonOrFallback(arr1, arr2) {
        // Ensure arr1 and arr2 are arrays
        if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
            return 1; // Return 1 if inputs are invalid
        }

        const common = arr1.find(num => arr2.includes(num)); // Find a common department ID

        if (common !== undefined) {
            return common; // Return the common ID if found
        } else {
            // If no common ID found, check arrays
            if (arr1.length > 0 && arr2.length > 0) {
                return arr1[0]; // Return the first ID from arr1
            } else if (arr1.length > 0) {
                return arr1[0]; // Return the first ID from arr1
            } else if (arr2.length > 0) {
                return arr2[0]; // Return the first ID from arr2
            } else {
                return 1; // If both are empty, return 1
            }
        }
    }


    async deleteMessage() {
        let messageId = this.extractMessageId(this.messageIdValue)
        let message = await this.streamChatClient.getMessage(messageId)
        if (this.streamChatClient.user.id === await message.message.user.id) {
            const messageElement = this.messageTarget;

            // Add a fade-out class
            messageElement.classList.add("transition-opacity", "duration-500", "opacity-0");

            // Wait for the fade-out transition to complete before removing the element
            setTimeout(() => {
                this.streamChatClient.deleteMessage(messageId, true);
                messageElement.remove();
            }, 500); // 500ms matches the duration of the fade-out
        }
    }


    extractMessageId(messageIdWithPrefix) {
        return messageIdWithPrefix.split('_')[1]
    }
}
