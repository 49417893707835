import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = [ "menuMobile", "menuBackdrop", "menu", "closeButton" ]

    async connect() {
        await this.setZIndex()
    }

    async setZIndex() {
        await this.setGlobalModalStateManager();
        this.globalModalStateManager.setZIndex(this.menuMobileTarget)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    showSidebar() {
        this.menuMobileTarget.classList.remove("hidden");
        enter(this.menuBackdropTarget);
        enter(this.menuTarget);
        enter(this.closeButtonTarget);
    }

    hideSidebar() {
        Promise.all([
            leave(this.menuBackdropTarget),
            leave(this.menuTarget),
            leave(this.closeButtonTarget),
        ]).then(() => {
            this.menuMobileTarget.classList.add("hidden");
        });
    }
}
