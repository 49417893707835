import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static values = { isEagerLoaded: Boolean, enableBackdropClick: Boolean}
    static targets = ["modalWithBackdrop", "modalContent", "closeIconWeb"]

    async connect() {
        await this.setZIndex()
        if(this.isEagerLoadedValue === false) {
            document.body.classList.toggle('overflow-hidden')
        }
    }

    async setZIndex() {
        await this.setGlobalModalStateManager();
        this.globalModalStateManager.setZIndex(this.modalWithBackdropTarget)
        this.globalModalStateManager.setZIndex(this.modalContentTarget)
        this.globalModalStateManager.setZIndex(this.closeIconWebTarget)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    disconnect() {
        document.body.classList.remove('overflow-hidden')
    }

    onBackdropClick(event) {
        // Only trigger if the click is on the targeted element (the backdrop), not on its descendants (the modal)
        if (event.target === event.currentTarget) {
            this.enableBackdropClickValue ? this.closeModal() : this.handleInOtherController();
        }
    }

    toggleModal() {
        this.modalWithBackdropTarget.classList.toggle('hidden')
        document.body.classList.toggle('overflow-hidden')
        const onToggleEvent = new CustomEvent("onModalToggle", {
            bubbles: true
        });

        this.element.dispatchEvent(onToggleEvent)
    }

    closeModal() {
        if (this.enableBackdropClickValue === true) {
            if (this.isEagerLoadedValue === true) {
                this.toggleModal()
            } else {
                document.body.classList.remove('overflow-hidden')
                this.modalWithBackdropTarget.remove();
            }
        } else {
            document.body.classList.remove('overflow-hidden')
            this.handleInOtherController();
        }
    }

    handleInOtherController() {
        const clickEvent = new CustomEvent("onModalBackdropClick", {
            bubbles: true
        });

        this.element.dispatchEvent(clickEvent);
    }

}
