import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";
export default class extends Controller {
    static targets = ["dots"];
    static values = { contractId: Number }

    async handleCardClick(event) {
        if (event.currentTarget !== this.dotsTarget) {
            // event.preventDefault()
            Turbo.visit(`/${window.currentWorkspaceSlug}/employee_zone/user_mobile/contracts/${this.contractIdValue}`);
        }
    }
}
