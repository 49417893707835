import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    async connect() {
        await this.setChannelDetailsController()
        // this.channelDetailsController.hideChatSidebar()
    }
    setChannelDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }
}