import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["newChildrenWrapper", "newChildTemplate", "existingChildren", "requiredEntry", "deleteButton"]
    static values = {
        collectionName: String,
    }

    initialize() {
        this.wrapperSelector = `.nested-form-wrapper[data-collection-name="${this.collectionNameValue}"]`;
    }

    existingChildrenTargetConnected(target) {
        this.toggleDeleteButtons()
    }
    existingChildrenTargetDisconnected(target) {
        this.toggleDeleteButtons()
    }

    addChild(e) {
        e.preventDefault()

        const content = this.newChildTemplateTarget.innerHTML
            .replace(
                new RegExp(`NEW_RECORD_${this.collectionNameValue}_END`, 'g'),
                `NEW_RECORD_${this.timestampString()}_END`
            )

        this.newChildrenWrapperTarget.insertAdjacentHTML('beforebegin', content)
        this.toggleDeleteButtons()
    }

    removeChild(e) {
        e.preventDefault()

        const wrapper = e.target.closest(this.wrapperSelector)

        if (wrapper.dataset.newRecord === 'true') {
            wrapper.remove()
        } else {
            wrapper.style.display = 'none'

            const input = wrapper.querySelector("input[name*='_destroy']")
            const counter = wrapper.querySelector("input[type='hidden'][name='childrenCounter']")
            counter.value = 0
            input.value = '1'
        }
        this.toggleDeleteButtons()
    }

    checkNeedEntry() {
        const field = this.requiredEntryTarget.querySelector("input[name = 'requiredEntry']")
        const value = field.value
        return value == 1
    }

    getChildCount() {
        let i = 0;
        this.existingChildrenTargets.forEach(child => {
            const counter = child.querySelector("input[type='hidden'][name='childrenCounter']")
            i += parseInt(counter.value) || 0
        })
        return i
    }

    toggleDeleteButtons() {
        if (this.checkNeedEntry()) {
            this.deleteButtonTargets.forEach(target => {
                if (this.getChildCount() > 1) {
                    target.classList.remove("hidden")
                } else {
                    if (!target.classList.contains("hidden")) {
                        target.classList.add("hidden")
                    }
                }
            })
        }
    }

    timestampString() {
        return new Date().getTime().toString()
    }
}
