import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
    static values = { isMobile: Boolean, employeeId: String, contractId: String}

    async openContract() {
        if (this.isMobileValue){
            Turbo.visit(`/${window.currentWorkspaceSlug}/employee_zone/user_mobile/contracts/${this.contractIdValue}`)
        } else {
            window.location.href = (`/${window.currentWorkspaceSlug}/employee_zone/employees/${this.employeeIdValue}/contracts/${this.contractIdValue}`)
        }
    }
}
