import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.maskOptions = {
      mask: '756.0000.0000.00',
      lazy: false, // make placeholder always visible
    };

    this.mask = IMask(this.inputTarget, this.maskOptions);

    this.mask.updateValue();
  }
}
