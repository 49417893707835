import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {

    connect() {
        tippy(
            this.element, {
                content: '<strong>Bolded <span style="color: aqua;">content</span></strong>',
            allowHTML: true,
        }
        );
    }
}
