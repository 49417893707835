import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = { emoji: String }
    connect() {
        this.messageId = this.element.getAttribute('data-message-id')
        this.reactionType = this.element.getAttribute('data-reaction-type')
        this.setStreamChatClient()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.setChannelDetailsController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setChannelDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.channelDetailsController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async addReaction() {
        const message = await this.chatIndexController.getMessageById(this.messageId)
        const isOwnMessage = await this.channelDetailsController.checkOwnReaction(message.message.own_reactions, this.reactionType)

        if (isOwnMessage) {
            this.channelDetailsController.removeReaction(this.messageId, this.reactionType)
        } else {
            this.channelDetailsController.sendReaction(this.messageId, this.reactionType, this.element.querySelector('.emoji').innerText)
        }
    }

}