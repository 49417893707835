import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
    static targets = ['input']

    connect() {
        this.maskOptions = {
            mask: '00000000.0',
            lazy: false, // make placeholder always visible
        };

        this.mask = IMask(this.inputTarget, this.maskOptions);

        this.mask.updateValue();
    }
}
