import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["colorButton"]

    connect() {
        const input = document.querySelector(".hidden-color-input")
        const startColor = input.value
        this.colorButtonTargets.forEach(button => {
            const targetButton = button.querySelector(".color-button")
            if (targetButton) {
                const compareColor = targetButton.dataset.color
                if (startColor == compareColor) {
                    const colorDiv = button.querySelector(".colored-div");
                    colorDiv.classList.add("border-2", "border-ink-primary", "p-0.5")
                }
            }
        })
    }

    changeColor(event) {
        const color = event.target.dataset.color;
        this.setBorder(event)
        this.setColor(color)
    }

    setBorder(event) {
        const targetDiv = event.target
        const parentDiv = targetDiv.parentElement
        this.resetBorders()
        parentDiv.classList.add("border-2", "border-ink-primary", "p-0.5")
    }

    resetBorders() {
        this.colorButtonTargets.forEach((target) => {
            const colorDiv = target.querySelector(".colored-div");
            colorDiv.classList.remove("border-2", "border-ink-primary", "p-0.5")
        })
    }

    setColor(color) {
        const input = document.querySelector(".hidden-color-input")
        input.value = color
    }
}
