import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["memberDropdown"];

    onChange(event) {
        const checkbox = event.currentTarget.querySelector('input[type="checkbox"]')
        this.changeMembers(checkbox.value, checkbox.checked)
    }

    changeMembers(target, isSelected) {
        const otherInputs = this.memberDropdownTarget.querySelector("#dropdown-menu")
        const checkboxes = otherInputs.querySelectorAll('input[type="checkbox"]')
        checkboxes.forEach(checkbox => {
            if (checkbox.value == target) {
                checkbox.checked = isSelected
                checkbox.dispatchEvent(new Event('click', { bubbles: true }));
            } else if (checkbox.value == this.selectedBefore) {
                checkbox.checked = false
                checkbox.dispatchEvent(new Event('click', { bubbles: true }));
            }
        });

        if (isSelected) { this.selectedBefore = target }
    }
}
