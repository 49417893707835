import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

    connect() {
        // Initialize or ensure the z-index starts where expected
        if (!this.zIndex) {
            this.zIndex = 10;
        }
    }

    // Method to get the next z-index and assign it to an element
    setZIndex(element) {
        this.zIndex += 10;
        element.style.zIndex = this.zIndex;
    }
}
