import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showMoreButton", "showLessButton", "showMoreContent", "showLessContent"]

  connect() {
    this.searchErrors()
  }

  showMore() {
    this.showMoreButtonTarget.classList.add('hidden')
    this.showLessButtonTarget.classList.remove('hidden')
    this.showMoreContentTarget.classList.remove('hidden')
    if (this.hasShowLessContentTarget) {
      this.showLessContentTarget.classList.add('hidden')
    }
    const expandedValue = document.querySelector("input[type='hidden']#show-more-hidden-expanded-input")
    if (expandedValue) {
      expandedValue.value = true
    }
  }

  showLess() {
    this.showMoreButtonTarget.classList.remove('hidden')
    this.showLessButtonTarget.classList.add('hidden')
    this.showMoreContentTarget.classList.add('hidden')
    if (this.hasShowLessContentTarget) {
      this.showLessContentTarget.classList.remove('hidden')
    }
    const expandedValue = document.querySelector("input[type='hidden']#show-more-hidden-expanded-input")
    if (expandedValue) {
      expandedValue.value = false
    }
  }

  searchErrors() {
    const errors = this.showMoreContentTarget.querySelectorAll(".field_with_errors")
    if (errors.length > 0) {
      this.showMore()
    }
  }
}
