import { Controller } from "@hotwired/stimulus"
import { StreamChat } from '../../../../libraries/stream_chat/library';
import MustacheHelper from "../../../../helpers/mustache_helper";
export default class extends Controller {
    static values = {
        streamChatApiKey: String,
        streamChatUserId: String,
        streamChatUserToken: String,
        sendChatNotifications: Boolean,
        deviceLoggedIn: Boolean,
        departmentChannelId: String,
        isConnectApp: Boolean,
        isNativeDevice: Boolean
    }

    async connect() {
        await this.initializeStreamChatClient()
        await this.loadNewMessages()

        this.streamChatClient.on(
            'notification.added_to_channel',
            event => this.loadNewMessages()
        )

        this.streamChatClient.on('message.new', event => {
            const channel = this.getChannel(event.channel_type, event.channel_id)
            const message = event.message

            // Check if the channel already exists in the local list
            const channelExists = this.channels.some(localChannel => localChannel.id === channel.id);

            if (!channelExists) {
                // Add the channel to your local list if needed
                this.channels.push(channel);
            }

            // Handle notifications if required
            if (this.sendChatNotificationsValue) {
                this.sendNotification(message);
            }
        })
    }

    async initializeStreamChatClient() {
        this.streamChatClient = StreamChat.getInstance(this.streamChatApiKeyValue);

        // Connect as observer (for devices)
        if (this.isConnectAppValue && !this.deviceLoggedInValue) {
            await this.streamChatClient.connectUser(
                { id: this.streamChatUserIdValue, role: 'observer' },
                this.streamChatUserTokenValue
            );
        } else {
            await this.streamChatClient.connectUser(
                { id: this.streamChatUserIdValue },
                this.streamChatUserTokenValue
            );
        }
    }

    async setChatDetailsController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index'

        this.chatDetailsController = this.application.getControllerForElementAndIdentifier(
            this.element.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async loadNewMessages() {
        await this.fetchNewMessages();
    }

    async sendNotification(message) {
        await this.setChatDetailsController()
        let messageId = `notification_${message.id}`
        if (message.user.id !== this.streamChatClient.user.id && (this.chatDetailsController !== null ? this.chatDetailsController.channel.cid !== message.cid : true) && !document.getElementById(messageId)) {
            let element = document.getElementById('chat-messages-notification-wrapper')
            const [channelType, channelId] = message.cid.split(':');
            const channel = this.getChannel(channelType, channelId)
            if (channel.state.members[this.streamChatClient.user.id].notifications_muted) {
                return;
            }
            const profilePicturePath = this.getProfilePicturePath(channel, [message.user])

            const messageHtml = message.html;
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = messageHtml;

            const firstParagraph = tempDiv.querySelector('p');
            if (firstParagraph) {
                firstParagraph.style.display = 'inline';
            }
            const modifiedHtml = tempDiv.innerHTML;

            const template = MustacheHelper.toElement(
                'components--employee-zone--chat--index--mustache-template--notification',
                {
                    initials: [message.user.firstname.charAt(0), message.user.lastname.charAt(0)].join(''),
                    channelTitle: this.getTitle(channel, [message.user]),
                    message: modifiedHtml,
                    profilePicturePath: profilePicturePath,
                    hasProfilePicture: profilePicturePath !== '' && profilePicturePath !== '#',
                    isGroupChat: channel.data.channel_type === 'group',
                    messageSender: [message.user.firstname, message.user.lastname].join(' '),
                    messageId: messageId,
                    channelId: channel.id
                }
            )

            element.appendChild(template)
        }
    }

    async fetchNewMessages() {
        this.channelEventListeners?.forEach(listener => listener.unsubscribe());
        this.channelEventListeners = [];

        if (this.isConnectAppValue && !this.deviceLoggedInValue && this.departmentChannelIdValue !== null) {
            this.channels = [await this.fetchChannelById(`department_${this.departmentChannelIdValue}`)]
        } else {
            this.channels = await this.fetchChannels()
        }

        this.channelEventListeners = this.channels.map(channel => {
            return channel.on('message.new', event => {
                if (this.sendChatNotificationsValue) {
                    this.sendNotification(event.message);
                }
            })
        })

        if (!this.isConnectAppValue) {
            let countElement = document.getElementById('employee-zone--navigation--navbar--utility-section--messages-count')
            let totalUnreadCount = 0
            for (const channel of this.channels) {
                totalUnreadCount += channel.countUnread()
            }
            countElement.innerText = totalUnreadCount !== 0 ? totalUnreadCount.toString() : null
        }
    }

    async fetchChannels(searchTerm = '', sortByName = false) {
        let sort = [{ last_message_at: -1 }];
        let filter = { type: 'messaging', members: { $in: [this.streamChatUserIdValue] }, last_message_at: { $exists: true } };
        if (searchTerm) {
            filter = {
                type: 'messaging',
                members: { $in: [this.streamChatUserIdValue] },
                $or: [
                    { name: { $autocomplete: searchTerm } },
                    { 'member.user.name': { $autocomplete: searchTerm } },
                    { hidden: true },  // Include hidden channels
                    { hidden: false }  // Include visible channels
                ]
            };
        }

        const newestChannelfilter = {
            type: 'messaging',
            members: { $in: [this.streamChatUserIdValue] }
        };

        const newestChannel = await this.streamChatClient.queryChannels(newestChannelfilter, {}, {
            watch: true,
            state: true,
            presence: true,
            limit: 1
        });

        let channels = await this.streamChatClient.queryChannels(filter, sort, {
            watch: true,
            state: true,
            presence: true,
            limit: 30,
            message_limit: 300
        });

        channels = this.sortChannelList(channels, sortByName)

        if (newestChannel[0] && newestChannel[0].data.channel_type === 'group') {
            const isChannelAlreadyListed = channels.some(channel => channel.id === newestChannel[0].id);

            // Add the newest channel to the start of the array if it's not already listed
            if (!isChannelAlreadyListed) {
                channels.unshift(newestChannel[0]); // Add it to the start of the channels array
            }
        }

        // If channels exceed the limit, remove the last one
        if (channels.length > 30) {
            channels.pop(); // Remove the last channel
        }

        return channels
    }

    async fetchChannelById(channel_id) {
        const filter = {
            id: { $eq: channel_id },
            $or: [
                { hidden: true },  // Include hidden channels
                { hidden: false }  // Include visible channels
            ]
        };
        const fetchedChannel = await this.streamChatClient.queryChannels(filter, {}, { limit: 1 });
        return fetchedChannel[0]
    }


    async updateUnreadMessageCount() {
        if (!this.isConnectAppValue) {
            let countElement = document.getElementById('employee-zone--navigation--navbar--utility-section--messages-count')
            let totalUnreadCount = 0
            for (const channel of this.channels) {
                totalUnreadCount += channel.countUnread()
            }
            countElement.innerText = totalUnreadCount !== 0 ? totalUnreadCount.toString() : null
        }
    }

    mapMemberProperty(otherMembers, property) {
        if (!otherMembers || otherMembers.length === 0) return '';

        return Object.values(otherMembers)
            .map(member => {
                const user = member.user || member;
                const value = user[property];
                return value && value.trim() ? value : null; // Ensure it's not empty or just whitespace
            })
            .filter(value => value !== null) // Filter out null values
            .join(', ');
    }
    getTitle(channel, otherMembers) {
        return channel.data.name || this.mapMemberProperty(otherMembers, 'name')
    }

    getProfilePicturePath(channel, otherMembers) {
        return channel.data.image || this.mapMemberProperty(otherMembers, 'profilePicturePath')
    }

    getChannel(channelType, channelId) {
        return this.streamChatClient.channel(channelType, channelId)
    }

    otherMembers(channel) {
        return (({ [this.streamChatUserIdValue]: _, ...rest }) => rest)(channel.state.members);
    }

    sortChannelList(channels, sortChannels = false) {
        if (!sortChannels) { return channels }
        const sortDirection = sortChannels == "asc" ? 1 : -1
        const channelArray = channels.map(channel => {
            const members = this.otherMembers(channel);
            const title = this.getTitle(channel, members);
            return { key: title, value: channel };
        });

        channelArray.sort((a, b) => {
            if (a.key < b.key) return -1 * sortDirection;
            if (a.key > b.key) return 1 * sortDirection;
            return 0;
        });

        const sortedChannels = channelArray.map(channelObject => channelObject.value);
        sortedChannels.forEach(channel => {
            const members = this.otherMembers(channel);
            const title = this.getTitle(channel, members);
        }
        )
        return sortedChannels;
    }
}
