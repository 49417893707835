import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['entry']

    removeEntry(event) {
        const button = event.currentTarget;
        const entryTarget = button.closest('[data-components--employee-zone--work-schedule--violation-messages-target="entry"]');
        if (entryTarget) {
            entryTarget.remove();
        }
        this.checkForModalInstantClose()
    }

    checkForModalInstantClose() {
        if (this.entryTargets.length < 1) {
            const element = document.querySelector('#bulk-create--instant-close-modal');
            element.dispatchEvent(new Event('click', { bubbles: true }));
        }
    }

}
