import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "form",
        "datePicker",
        "progressBar",
        "completeness",
        "uploadPhoto",
        "hiddenFileUpload",
        "avatar"
    ];

    connect() {
        super.connect();
        // only for compatibility to test web responsive
        document.addEventListener("DOMContentLoaded", () => this.initializeProgressCompleteness());
        document.addEventListener("turbo:load", () => this.initializeProgressCompleteness());

        this.uploadPhotoTarget.addEventListener("click", (e) => this.triggerFileUpload(e));
        this.hiddenFileUploadTarget.addEventListener("change", (e) => this.setProfilePictureToAvatar(e));
    }

    triggerFileUpload(e) {
        e.preventDefault();
        this.hiddenFileUploadTarget.click();
    }

    setProfilePictureToAvatar(e) {
        const file = e.target.files[0];
        if (file) {
            const imgSrc = URL.createObjectURL(file);
            this.avatarTarget.querySelector(".initials-background").innerHTML = `
                    <div class="rounded-full h-full w-full overflow-hidden flex items-center justify-center">
                        <img src="${imgSrc}" class="w-full h-full object-cover">
                    </div>`;
        }
    }

    initializeProgressCompleteness() {
        const fields = this.formTarget.querySelectorAll("input:not([type=hidden]):not(.numInput):not(.invisible):not(.hidden)");

        this.detectDropdownChanges(fields);
        fields.forEach(field => {
            field.addEventListener("input", () => {
                this.calculateProfileCompleteness(fields);
            });
        });
        this.calculateProfileCompleteness(fields);
    }

    calculateProfileCompleteness(fields) {
        const totalFields = fields.length;
        const filledFields = Array.from(fields).filter(field => field.value.trim() !== ""
            && (
                !field.hasAttribute("data-components--form--social-security-number-target") ||
                field.value.trim() !== "756.____.____.__")).length;
        const percentageFilled = totalFields > 0 ? (filledFields / totalFields) * 100 : 0;
        this.progressBarTarget.querySelector("div").style.width = percentageFilled.toFixed(2) + "%";
        this.completenessTarget.textContent = percentageFilled.toFixed(1) + "%"
    }

    detectDropdownChanges(fields) {
        document.addEventListener("selectDropdownSelected", () =>
            this.calculateProfileCompleteness(fields));
    }

    disconnect() {
        document.removeEventListener("DOMContentLoaded", () => this.initializeProgressCompleteness());
        document.removeEventListener("turbo:load", () => this.initializeProgressCompleteness());
        this.uploadPhotoTarget.removeEventListener("click", (e) => this.triggerFileUpload(e));
        this.hiddenFileUploadTarget.removeEventListener("change", (e) => this.setProfilePictureToAvatar(e));
        super.disconnect();
    }
}