import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dropDown", "hiddenInput", "previewIcon", "selectableIcon", "previewPlaceholder"]

    async connect() {
        await this.setZIndex()
        this.syncStartInput()
        this.open = false
    }

    async setZIndex() {
        await this.setGlobalModalStateManager();
        this.globalModalStateManager.setZIndex(this.dropDownTarget)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    syncStartInput() {
        const iconValue = this.hiddenInputTarget.value
        this.openImageSelectedImage(iconValue)
        this.setSelectColor(iconValue)
    }

    toggleDropDown() {
        this.open = !this.open
        this.open ? this.openDropDown() : this.closeDropDown()
    }

    openDropDown() {
        const dropDown = this.dropDownTarget
        const placeholder = this.previewPlaceholderTarget;
        dropDown.classList.remove("hidden");
        placeholder.classList.add("border-2", "border-ink");
    }

    closeDropDown() {
        const dropDown = this.dropDownTarget;
        const placeholder = this.previewPlaceholderTarget;
        dropDown.classList.add("hidden");
        placeholder.classList.remove("border-2", "border-ink");
    }

    selectIcon(event) {
        const input = event.currentTarget.querySelector('input.icon-value');
        const iconValue = input.value;
        this.hiddenInputTarget.value = iconValue;
        this.openImageSelectedImage(iconValue);
        this.setSelectColor(iconValue);
        this.open = false;
        this.closeDropDown();
    }

    setSelectColor(selectedValue) {
        this.selectableIconTargets.forEach(element => {
            const icon = element.querySelector('div');
            const input = element.querySelector('input.icon-value');
            if (input.value == selectedValue) {
                if (!icon.classList.contains('bg-canvas-medium')) {
                    icon.classList.add('bg-canvas-medium')
                }
            } else {
                icon.classList.remove('bg-canvas-medium')
            }
        });
    }

    openImageSelectedImage(imageValue) {
        this.previewIconTargets.forEach(element => {
            const icon = element.querySelector('div');
            if (icon.dataset.icon == imageValue) {
                icon.classList.remove("hidden");
            }
            else if (!icon.classList.contains("hidden")) {
                icon.classList.add("hidden");
            }
        });
    }

}
