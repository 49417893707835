import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = ["grossWageDiv", "totalDeductionsDiv", "totalAllowancesDiv", "totalNetSalaryDiv", "thirteenthSalary"]
    static values = { isMonthlyPayed: Boolean, workspaceSlug: String, employeeId: Number, contractId: Number, state: Number, defaultWorkingHours: Number }
    connect() {
        this.inputValue = document.body.querySelector('#contract_salary_reduction_type').value
        this.thirteenthPaymentVariantValue = this.thirteenthSalaryTarget.getAttribute('data-thirteenth-payment-variant-value')
        this.onInput()
    }

    onInput() {
        if (this.hasGrossWageDivTarget) {
            this.calculateGrossWage()
            this.calculateSalaryReductions()
            this.calculateSalaryAllowances()
            this.calculateSalaryDeductions()
            this.calculateTotalNetSalary()
        }
    }

    calculateGrossWage() {
        const salaryElement = document.querySelector("[id*='contract_salary'][type='hidden']")
        const turnoverSalaryElement = document.querySelector("[id*='contract_turnover_salary'][type='hidden']")
        let thirteenthSalaryElement = document.querySelector("[id*='contract_thirteenth_salary'][type='hidden']")
        const customSalaries = document.querySelectorAll("[name*='contract_additional_salaries_attributes'][name*='value']");

        const cleanedSalary = salaryElement ? salaryElement.value.replace(/['\s]/g, '') : 0;
        const cleanedTurnoverSalary = turnoverSalaryElement ? turnoverSalaryElement.value.replace(/['\s]/g, '') : 0;
        const salary = salaryElement && !isNaN(Number(cleanedSalary)) ? Number(cleanedSalary) : 0
        const turnoverSalary = turnoverSalaryElement && !isNaN(Number(cleanedTurnoverSalary)) ? Number(cleanedTurnoverSalary) : 0
        let thirteenthSalary;
        let thirteenthSalaryPerType;
        if (this.isMonthlyPayedValue === true) {
            if (this.thirteenthPaymentVariantValue === 'monthly') {
                thirteenthSalary = salary / 12
            } else if (this.thirteenthPaymentVariantValue === 'hourly') {
                thirteenthSalary = salary / 12 / 4.35 / this.defaultWorkingHoursValue //  4.35 is the average of weeks per month
            } else {
                thirteenthSalary = salary
            }
            thirteenthSalaryPerType = salary / 12
        } else {
            if (this.thirteenthPaymentVariantValue === 'monthly') {
                thirteenthSalary = salary * this.defaultWorkingHoursValue * 4.35 / 12
            } else if (this.thirteenthPaymentVariantValue === 'hourly') {
                thirteenthSalary = (salary * this.defaultWorkingHoursValue * 4.35) / (this.defaultWorkingHoursValue * 4.35)
            } else {
                thirteenthSalary = salary * this.defaultWorkingHoursValue * 4.35
            }
            thirteenthSalaryPerType = (salary * this.defaultWorkingHoursValue * 4.35) / (this.defaultWorkingHoursValue * 4.35)
        }
        this.thirteenthSalaryTarget.innerText = thirteenthSalary.toFixed(2);
        thirteenthSalaryElement.value = thirteenthSalary.toFixed(2);

        const totalAdditionalSalaries = Array.from(customSalaries).reduce((sum, element) => {
            let salary_type = element.closest('[data-salary-type-id-value]').getAttribute('data-salary-type-id-value')
            let salary_unit = element.closest('[data-salary-unit-id-value]').getAttribute('data-salary-unit-id-value')
            let value = element.value ? Number(element.value.replace(/['\s]/g, '')) : 0; // Convert to number, defaulting to 0 if conversion fails or if null

            // check if its a percent of the salary
            if (Number(salary_unit) !== 1) {
                value = salary * (value / 100)
            }

            if (this.isMonthlyPayedValue === true) {
                if (salary_type === '3') {
                    if (salary_unit !== '1') {
                        value = salary * (value / 12 / 100)
                    } else {
                        value = value / 12
                    }
                } else if (salary_type === '2') {
                    if (salary_unit !== '1') {
                        value = salary * (value * 4.35 * this.defaultWorkingHoursValue / 100)
                    } else {
                        value = value * 4.35 * this.defaultWorkingHoursValue
                    }
                } else {
                    if (salary_unit !== '1') {
                        value = salary * (value / 100)
                    }
                }
            } else {
                if (salary_type === '3') {
                    if (salary_unit !== '1') {
                        value = salary * (value / 12 / this.defaultWorkingHoursValue / 4.35 / 100)
                    } else {
                        value = value / 12 / this.defaultWorkingHoursValue / 4.35
                    }
                } else if (salary_type === '1') {
                    if (salary_unit !== '1') {
                        value = salary * (value / this.defaultWorkingHoursValue / 4.35 / 100)
                    } else {
                        value = value / this.defaultWorkingHoursValue / 4.35
                    }
                } else {
                    if (salary_unit !== '1') {
                        value = salary * (value / 100)
                    }
                }
            }
            return sum + (!isNaN(value) ? value : 0); // Add to sum, skip NaN values by adding 0 instead
        }, 0);

        let gross_wage = this.formatNumber(salary + turnoverSalary + thirteenthSalaryPerType + totalAdditionalSalaries)
        if (this.grossWageDivTarget.innerText !== gross_wage) {
            this.sendRequest(salary + turnoverSalary + thirteenthSalaryPerType + totalAdditionalSalaries)
        }
        this.grossWageDivTarget.innerText = gross_wage
    }

    convertValue(value, salary_type) {
        if ((this.isMonthlyPayedValue && Number(salary_type) === 1) || (!this.isMonthlyPayedValue && Number(salary_type) === 2)) {
            return value
        } else {
            return 0
        }
    }

    async sendRequest(gross_wage) {
        const response = await get(
            `/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/salary/salary_deduction/${this.contractIdValue}/edit?gross_wage=${gross_wage}`,
            { responseKind: "turbo-stream" }
        );

        if (response.ok) {
            // Need timeout to be able to set e.g. variable deductions on time and then calculate them 
            setTimeout(() => {
                this.calculateSalaryReductions()
                this.calculateSalaryAllowances()
                this.calculateSalaryDeductions()
                this.calculateTotalNetSalary()
            }, 10);
        }
    }
    calculateSalaryReductions() {

    }

    calculateSalaryAllowances() {
        const elements = document.querySelectorAll('[name*="contract_salary_allowances_attributes"][name*="value"]');
        const grossWage = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        let total = 0
        elements.forEach(element => {
            if (element.getAttribute('overlay_label') == 'percentage') {
                total += grossWage * ((!isNaN(Number(element.value)) ? Number(element.value) : 0) / 100)
            } else {
                total += !isNaN(Number(element.value)) ? Number(element.value) : 0
            }
        });

        this.totalAllowancesDivTarget.innerText = this.formatNumber(total)
    }

    calculateSalaryDeductions() {
        const elements = document.querySelectorAll('[name*="contract_salary_deductions_attributes"][name*="value"]');
        const grossWage = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        let total = 0
        elements.forEach(element => {
            const overlayLabel = element.getAttribute('overlay_label');
            if (overlayLabel == 'percentage' || overlayLabel == null) {
                const currencyTarget = element.parentNode.parentNode.parentNode.parentNode.querySelector("#salary--deduction--extra-label")
                let currencyValue = grossWage * ((!isNaN(Number(element.value)) ? Number(element.value) : 0) / 100)
                if (currencyTarget) {
                    let input = currencyTarget.querySelector('input:not([type="hidden"])')
                    input.value = this.formatNumber(currencyValue)
                }
                total += currencyValue
            } else {
                total += !isNaN(Number(element.value)) ? Number(element.value) : 0
            }
        });
        this.totalDeductionsDivTarget.innerText = this.formatNumber(total)
    }

    convertCurrencyInPercent(event) {
        const eventTarget = event.currentTarget.parentNode.parentNode.parentNode.querySelector("#salary--deduction--main-input")
        const currencyValue = Number(this.convertToNumber(event.target.value)) || 0
        const grossWage = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        if (eventTarget && grossWage > 0 && currencyValue >= 0) {
            let percentage = (currencyValue / grossWage) * 100
            let input = eventTarget.querySelector('input:not([type="hidden"])')
            input.value = this.formatNumber(percentage)

            if (this.timer) {
                clearTimeout(this.timer);
            }

            //timer to set the value from the percentage in the form
            this.timer = setTimeout(() => {
                const newEvent = new Event('input', { bubbles: true });
                input.dispatchEvent(newEvent);
                this.timer = null
            }, 2000);
        }
    }

    calculateTotalNetSalary() {
        const grossSalary = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        const salaryDeduction = Number(this.convertToNumber(this.totalDeductionsDivTarget.innerText))
        const salaryAllowances = Number(this.convertToNumber(this.totalAllowancesDivTarget.innerText))

        this.totalNetSalaryDivTarget.innerText = this.formatNumber(grossSalary + salaryAllowances - salaryDeduction)
    }

    formatNumber(number) {
        // Round the number to two decimal places
        const roundedNumber = Math.round(number * 100) / 100;

        // Convert the rounded number to a string and split into integer and decimal parts
        const [integerPart, decimalPart] = roundedNumber.toFixed(2).split('.');

        // Format the integer part using the 'fr-CH' locale for space as a thousand separator
        const formattedIntegerPart = new Intl.NumberFormat('fr-CH').format(parseInt(integerPart));

        // Replace spaces with apostrophes in the integer part
        const integerWithApostrophes = formattedIntegerPart.replace(/\s/g, "'");

        // Concatenate the integer part with the rounded decimal part
        return `${integerWithApostrophes}.${decimalPart}`;
    }

    convertToNumber(str) {
        // Remove apostrophe and convert to number
        return parseFloat(str.replace(/'/g, ''));
    }
}
