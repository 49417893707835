import { Controller } from "@hotwired/stimulus"
import {get, put} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "showAll", "showLess" ]

    async connect() {
        await this.setZIndex()
        this.readNotifications = document.getElementsByClassName('read-in-app-notifications')
        this.inAppNotifications = document.getElementsByClassName('in-app-notifications')
    }

    async setZIndex() {
       await this.setGlobalModalStateManager();
       this.globalModalStateManager.setZIndex(this.element)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async showAll() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/unread_notifications`, { responseKind: 'turbo-stream' })
        this.showAllTarget.classList.add('hidden')
        this.showLessTarget.classList.remove('hidden')
    }

    showLess() {
        for (const element of  this.readNotifications) {
            element.classList.add('hidden')
        }
        this.showAllTarget.classList.remove('hidden')
        this.showLessTarget.classList.add('hidden')
    }

    markAllAsRead() {
        for (const element of this.inAppNotifications) {
            this.sendRequest(element)
        }
    }

    markAsRead(event) {
        this.sendRequest(event.currentTarget)
    }

    async sendRequest(element) {
        if (!element.classList.contains('read-in-app-notifications')) {
            const response = await put(`/${window.currentWorkspaceSlug}/employee_zone/notifications/${element.getAttribute('data-notification-id')}`)
            if (response.ok) {

                // Add a fade-out class
                element.classList.add("transition-opacity", "duration-500", "opacity-0");

                // Wait for the fade-out transition to complete before removing the element
                setTimeout(() => {
                    element.remove();
                }, 500); // 500ms matches the duration of the fade-out
            }
        }
    }
}
