import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = ["dropdownMenu", "selectButton", "arrowUp", "arrowDown"]

    async connect() {
        await this.setZIndex()
        document.addEventListener('click', this.outsideClick.bind(this))
    }

    async setZIndex() {
        await this.setGlobalModalStateManager();
        this.globalModalStateManager.setZIndex(this.dropdownMenuTarget)
    }

    setGlobalModalStateManager() {
        const controllerName = 'components--employee-zone--layout-template--global-modal-state-manager'

        this.globalModalStateManager = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    toggleDropdown(event) {
        event.stopPropagation()

        this.selected_style = this.selectButtonTarget.getAttribute('data-selected-style').split(' ')
        if (this.dropdownMenuTarget.classList.contains('hidden')) {
            this.showDropdown();
        } else {
            this.hideDropdown();
        }
    }

    showDropdown() {
        this.isSelected()
        this.dropdownMenuTarget.classList.remove("hidden");
        enter(this.dropdownMenuTarget);
    }

    hideDropdown() {
        this.isNotSelected()
        this.dropdownMenuTarget.classList.add('hidden')
        leave(this.dropdownMenuTarget)
        this.dispatch('closed')
    }

    outsideClick(event) {
        if (!this.element.contains(event.target) && !this.dropdownMenuTarget.classList.contains('hidden')) {
            this.hideDropdown();
        }
    }

    isSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.add(this.selected_style[style])
            if (this.selectButtonTarget.classList.contains("border-transparent")) {
                this.selectButtonTarget.classList.remove("border-transparent")
                this.hasOriginBorder = true
            }
        }
        this.toggleArrows()
    }

    isNotSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.remove(this.selected_style[style])
            if (this.hasOriginBorder && !this.selectButtonTarget.classList.contains("border-transparent")) {
                this.selectButtonTarget.classList.add("border-transparent")
            }
        }
        this.toggleArrows()
    }

    toggleArrows() {
        if (this.hasArrowUpTarget && this.hasArrowDownTarget) {
            if (this.arrowUpTarget.classList.contains('hidden')) {
                this.arrowDownTarget.classList.add('hidden')
                this.arrowUpTarget.classList.remove('hidden')
            } else {
                this.arrowDownTarget.classList.remove('hidden')
                this.arrowUpTarget.classList.add('hidden')
            }
        }
    }
}
