import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["sortBy"]
    connect() {
        this.setChatIndexController()
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setSortByController() {
        const sortByElement = this.sortByTarget.querySelector("#element--sort-by");
        const controllerName = 'components--element--sort-by'
        this.sortByController = this.application.getControllerForElementAndIdentifier(
            sortByElement,
            controllerName
        )
    }

    changeChatDirection(event) {
        if (!this.sortByController) {
            this.setSortByController()
        }
        this.sortDirection = this.sortByController.sortDirection
        this.reloadChatsWithFilter();
    }

    changeChatScope(event) {
        if (!this.sortByController) {
            this.setSortByController()
        }
        this.scopeValue = this.sortByController.scopeTarget.value
        this.reloadChatsWithFilter();
    }

    reloadChatsWithFilter() {
        if (this.scopeValue == 1) {
            this.chatIndexController.reloadChannelList(this.sortDirection);
        } else {
            this.chatIndexController.reloadChannelList();
        }
    }

}
