import { Controller } from "@hotwired/stimulus"
import Mark from 'mark.js'

export default class extends Controller {
    static values = { messageId: String }
    connect() {
        this.setMessageController()
        this.setChatIndexController()
        this.setStreamChatController()
        this.loadChatList()
    }

    forwardMessage() {
        if (this.messageIdValue) {
            this.getMessageControllerAfterLoad()
            this.messageController.forwardMessage(this.messageIdValue);
            this.messageIdValue = null
            this.closeModal()
        }
    }

    async loadChatList() {
        let chatListWrapper = document.getElementById('forward-message-channel-list');
        this.filteredChannels = await this.streamChatController.fetchChannels()
        chatListWrapper.innerHTML = '';
        this.filteredChannels.forEach(channel => {
            const otherMembers = this.otherMembers(channel);
            this.chatIndexController.displayChats(chatListWrapper, otherMembers, channel, '');
        });
    }

    closeModal() {
        const closeButton = document.querySelector("#components--element--modal--forward-message--close")
        if (closeButton)
            closeButton.click();
    }

    async getMessageControllerAfterLoad() {
        await this.waitForControllerLoad();
    }
    waitForControllerLoad() {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                this.setMessageController()
                if (this.messageController) {
                    clearInterval(interval);
                    resolve();
                }
            }, 0);
        });
    }

    setMessageController() {
        const controllerName = 'components--employee-zone--chat--channel-details--index--mustache-template--message'

        this.messageController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            document.querySelector(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setStreamChatController() {
        const controllerName = 'components--employee-zone--layout-template--stream-chat'

        this.streamChatController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    async onSearchInput(event) {
        const searchTerm = event.currentTarget.querySelector('input').value.toLowerCase();
        let chatListWrapper = document.getElementById('forward-message-channel-list');
        let searchListItemWrapper = document.getElementById('forward-message-search-channel-list');
        chatListWrapper.innerHTML = '';
        searchListItemWrapper.innerHTML = ''
        if (searchTerm == '') {
            this.loadChatList()
        } else {

            this.filteredChannels = await this.streamChatController.fetchChannels(searchTerm);

            searchListItemWrapper.innerHTML = '';
            // Render the filtered list
            this.filteredChannels.forEach(channel => {
                const otherMembers = this.otherMembers(channel);
                this.chatIndexController.displayChats(searchListItemWrapper, otherMembers, channel, '');
            });

            // Apply highlighting to the search term
            this.mark = new Mark(searchListItemWrapper);
            this.mark.mark(searchTerm, {
                className: 'bg-blue-light'
            });
        }


    }

    otherMembers(channel) {
        return (({ [this.streamChatController.streamChatUserIdValue]: _, ...rest }) => rest)(channel.state.members);
    }
}
