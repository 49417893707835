import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['hiddenInputWrapper', 'buttonEnabled', 'buttonDisabled']

    connect() {
        this.updateButton();
    }

    toggle() {
        let input = this.hiddenInputWrapperTarget.querySelector('#toggle-active')
        input.checked = !input.checked
        this.updateButton();
    }

    updateButton() {
        const toggleInput = this.hiddenInputWrapperTarget.querySelector('input[name="toggle-active"]');
        if (this.hiddenInputWrapperTarget.querySelector('#toggle-active').checked) {
            this.buttonEnabledTarget.classList.add('hidden')
            this.buttonDisabledTarget.classList.remove('hidden')
            toggleInput.value = 1;
        } else {
            this.buttonDisabledTarget.classList.add('hidden')
            this.buttonEnabledTarget.classList.remove('hidden')
            toggleInput.value = 0;
        }
    }

}